import React, { Fragment, useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { getElectronicMessage } from '../../actions/HomePageActions';
import parse from "html-react-parser";
import Loader from '../layout/Loader';
import MetaData from '../layout/MetaData';

const ElectronicMessage = () => {
    const dispatch = useDispatch();
    const { ElectronicMsg, loading } = useSelector(state => state.electronicMsg);

    useEffect(() => {
        if (!ElectronicMsg) {
            dispatch(getElectronicMessage());
        }
    }, [dispatch, ElectronicMsg])

    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <MetaData title={`Electronic Commercial Message`} />
                <Header />
                <section className="testimonal container py-5">
                    <h2 className="mt-5 mb-3 text-center black fm font-40 ">{ElectronicMsg?.page?.title}</h2>
                    {ElectronicMsg?.page && parse(ElectronicMsg?.page?.content)}
                </section>
                <Footer />
            </Fragment>
        </Fragment>
    )
}

export default ElectronicMessage