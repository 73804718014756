import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAttributes } from '../../../actions/productActions';
import $ from 'jquery';
import { getLastURL, ShapeImageUrl } from '../../../helper';
import { getAllProductsFilter } from '../../../actions/productActions';
import { useLocation } from 'react-router';
import { CaratColorJson, ColorMeasurementJson } from '../../../utils/json';
import { FormattedMessage, useIntl } from 'react-intl';
import mixpanel from 'mixpanel-browser';

const Sidebar = ({ tabChangeHandler }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const pathname = getLastURL(location);
    const { attributes } = useSelector(state => state.attributes);

    useEffect(() => {
        dispatch(getAttributes('color-stones'))
    }, [dispatch])

    const [shape, setShape] = useState([]);
    const [stonetypeCheck, setStoneTypeCheck] = useState(new Array(attributes?.stone_type?.length).fill(false));
    const [stone_type, setStoneType] = useState([]);
    const [price, setPrice] = useState({
        from: "",
        to: ""
    })
    const [carat, setCarat] = useState({
        from: "",
        to: ""
    })
    const [caratcheckboxCheck, setCaratCheckboxCheck] = useState(new Array(CaratColorJson && CaratColorJson.length).fill(false))
    const [measurementcheckboxCheck, setMeasurementCheckboxCheck] = useState(new Array(ColorMeasurementJson && ColorMeasurementJson.length).fill(false))
    const [measurement, setMeasurement] = useState({
        length: "",
        width: ""
    })
    const [certificateCheck, setCertificateCheck] = useState(new Array(attributes && attributes.certificate && attributes.certificate.length).fill(false));
    const [certificate, setCertificate] = useState([]);
    const [supplierCheck, setSupplierCheck] = useState(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false));
    const [supplier, setSupplier] = useState([]);

    const loadMoreDefault = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr' ? 'Daha fazla gör' : 'See More';
        let see_less = localStorage.getItem('lang') == 'tr' ? 'Daha Az Gör' : 'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; ' + see_more)
        } else {
            $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; ' + see_less)
        }
    }


    const loadMoreShape = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr' ? 'Daha fazla gör' : 'See More';
        let see_less = localStorage.getItem('lang') == 'tr' ? 'Daha Az Gör' : 'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            // $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .c_hide').attr('style', 'display: none !important;')
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; ' + see_more)
        } else {
            // $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').attr('style', 'display: inline-block !important;')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; ' + see_less)
        }
    }


    const handleFilterShape = (id) => {
        if (!shape.includes(id)) {
            setShape([...shape, id]);
        } else {
            shape.splice(shape.indexOf(id), 1);
        }


        if ($('.shape-items-show li.shapec_' + id).hasClass('active')) {
            $('.shape-items-show li.shapec_' + id).removeClass('active')

        } else {
            $('.shape-items-show li.shapec_' + id).addClass('active')
        }
    }


    const handleFilterStoneType = (position, id) => {
        const updatedStoneType = stonetypeCheck && stonetypeCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setStoneTypeCheck(updatedStoneType);

        if (!stone_type.includes(id)) {
            setStoneType([...stone_type, id]);
        } else {
            stone_type.splice(stone_type.indexOf(id), 1);
        }
    }

    const handlePrice = (event) => {
        setPrice({ ...price, [event.target.name]: event.target.value });
    }

    const handleCarat = (event) => {
        setCarat({ ...carat, [event.target.name]: event.target.value });
    }

    const handleMeasurement = (event) => {
        setMeasurement({ ...measurement, [event.target.name]: event.target.value });
    }

    const handleFilterCertitficate = (position, id) => {
        const updateCertificate = certificateCheck && certificateCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setCertificateCheck(updateCertificate);

        if (!certificate.includes(id)) {
            setCertificate([...certificate, id]);
        } else {
            certificate.splice(certificate.indexOf(id), 1);
        }
    }


    const handleFilter = (e) => {
        e.preventDefault();
        mixpanel.track('Color_Stones_filter Jan25');
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname, { shapes: shape, certificate: certificate, price: price, stone_type: stone_type, carat: carat, measurement: measurement, seller: supplier }));
    }

    const handleResetAll = () => {

        setShape([]);
        setStoneTypeCheck(new Array(attributes && attributes.stone_type && attributes.stone_type.length).fill(false));
        setStoneType([]);
        setPrice({
            from: "",
            to: ""
        })
        setCaratCheckboxCheck(new Array(CaratColorJson && CaratColorJson.length).fill(false))
        setCarat({
            from: "",
            to: ""
        })
        setMeasurementCheckboxCheck(new Array(ColorMeasurementJson && ColorMeasurementJson.length).fill(false))
        setMeasurement({
            length: "",
            width: ""
        })
        setSupplierCheck(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false))
        setSupplier([])
        setCertificateCheck(new Array(attributes && attributes.certificate && attributes.certificate.length).fill(false));
        setCertificate([]);


        if ($('.shape-items-show li').hasClass('active')) {
            $('.shape-items-show li').removeClass('active')
        }
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname, {}));
    }


    const handleFilterCaratCheckbox = (From, To, position) => {

        const updateCaratCheckbox = caratcheckboxCheck && caratcheckboxCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return false;
            }
        });

        setCaratCheckboxCheck(updateCaratCheckbox);
        if (updateCaratCheckbox[position]) {
            setCarat({
                from: From,
                to: To
            })
        } else {
            setCarat({
                from: '',
                to: ''
            })
        }


    }

    const handleFilterMeasurementCheckbox = (length, width, position) => {

        const updateMeasurementCheckbox = measurementcheckboxCheck && measurementcheckboxCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return false;
            }
        });

        setMeasurementCheckboxCheck(updateMeasurementCheckbox);

        if (updateMeasurementCheckbox[position]) {
            setMeasurement({
                length: length,
                width: width
            })
        } else {
            setMeasurement({
                length: '',
                width: ''
            })
        }
    }


    const handleSupplier = (position, id) => {
        const updatedSupplier = supplierCheck && supplierCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSupplierCheck(updatedSupplier);

        if (!supplier.includes(id)) {
            setSupplier([...supplier, id]);
        } else {
            supplier.splice(supplier.indexOf(id), 1);
        }
    }


    return (
        <div className="col-lg-3">
            <div className="dashboard-sidebar ">
                <form onSubmit={handleFilter}>
                    <div className="filter_list Jewelery_list">
                        <div className="filter_side">



                            <div className="filter_top mt-3 cs_stonetype">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb page_head" ><FormattedMessage id="category.color-stones" defaultMessage="Color Stones" />  </h4>
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="StoneType" defaultMessage="Stone Type" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.stone_type && attributes.stone_type.map((data, key) => {
                                            return (
                                                <div className={key < 8 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={stonetypeCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterStoneType(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('cs_stonetype')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>

                            <div className="filter_top cs_shape">
                                <div className="filter_head">
                                    <p className="font-16 black-3B fm "><FormattedMessage id="addsingleproduct.shape" defaultMessage="Shape" /></p>
                                </div>
                                <ul id="myList" className="shape-items-show">
                                    {
                                        attributes && attributes?.shape && attributes.shape.map((shape, key) => {
                                            return (
                                                <li className={key < 8 ? 'c_show shapec_' + shape.id : 'c_hide shapec_' + shape.id} onClick={() => handleFilterShape(shape.id)}><img src={ShapeImageUrl(shape.image)} alt="" /><span>{shape.name}</span></li>
                                            )
                                        })
                                    }
                                </ul>
                                <p id="loadMore" onClick={(e) => loadMoreShape('cs_shape')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>


                            <div className="filter_top mt-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.measurement" defaultMessage="Measurement" /></h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" placeholder={useIntl().formatMessage({ id: 'length' })} value={measurement.length} name="length" onChange={handleMeasurement} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={measurement.width} name="width" onChange={handleMeasurement} placeholder={useIntl().formatMessage({ id: 'width' })} />
                                    </div>
                                </div>

                                <div className="filter_top mt-3 d_measurement_checkbox">
                                    <div className="multi_price">
                                        {
                                            ColorMeasurementJson && ColorMeasurementJson.map((data, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={data.display_name} checked={measurementcheckboxCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterMeasurementCheckbox(data.length, data.width, key)} />
                                                        <label htmlFor={data.id}>{data.display_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/* <p id="loadMore" onClick={(e) => loadMoreDefault('d_measurement_checkbox')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; See More</p> */}
                                </div>
                            </div>


                            <div className="filter_top">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat" /></h4>
                                </div>

                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" value={carat.from} placeholder={useIntl().formatMessage({ id: 'Low' })} name="from" onChange={handleCarat} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" value={carat.to} className="form-control" name="to" onChange={handleCarat} placeholder={useIntl().formatMessage({ id: 'High' })} />
                                    </div>
                                </div>

                                <div className="filter_top mt-3 d_carat_checkbox">
                                    <div className="multi_price">
                                        {
                                            CaratColorJson && CaratColorJson.map((data, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={data.display_name} checked={caratcheckboxCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterCaratCheckbox(data.from, data.to, key)} />
                                                        <label htmlFor={data.id}>{data.display_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/* <p id="loadMore" onClick={(e) => loadMoreDefault('d_carat_checkbox')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; See More</p> */}
                                </div>

                            </div>



                            <div className="filter_top mt-3 cs_lab">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.certificate" defaultMessage="Certificate" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.certificate && attributes.certificate.map((data, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={certificateCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterCertitficate(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('cs_lab')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>


                            <div className="filter_top mt-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.price-ct" defaultMessage="Price/ct" /></h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" placeholder={useIntl().formatMessage({ id: 'Low' })} value={price.from} name="from" onChange={handlePrice} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={price.to} name="to" onChange={handlePrice} placeholder={useIntl().formatMessage({ id: 'High' })} />
                                    </div>
                                </div>
                            </div>


                            <div className="filter_top mt-3  d_seller">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="Supplier" defaultMessage="Supplier" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.top_sellers && attributes.top_sellers.map((data, key) => {
                                            return (
                                                <div className={key < 20 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={supplierCheck[key]} id={data.id} className="checkbox" onChange={() => handleSupplier(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name} ({data.product_count})</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_seller')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>

                        </div>
                        <div className="filter_top">
                            <div className="filter_btn">
                                <button className="btn btn-black" type="submit"><FormattedMessage id="ApplyFilters" defaultMessage="Apply filters" /></button>
                                <p className="font-14 gray-65 text-center mt-3" style={{ cursor: 'pointer' }} onClick={handleResetAll}><FormattedMessage id="Reset_all" defaultMessage="Reset all" /> <span className="icon-close_1"></span></p>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>

    )
}

export default Sidebar



