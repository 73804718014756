export const ALL_PRODUCTS_REQUEST = 'ALL_PRODUCT_REQUEST'
export const ALL_PRODUCTS_SUCCESS = 'ALL_PRODUCT_SUCCESS'
export const ALL_PRODUCTS_FAIL = 'ALL_PRODUCT_FAIL'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST'
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAIL = 'DOWNLOAD_FAIL'

export const ALL_PRODUCT_TYPE_REQUEST = 'ALL_PRODUCT_TYPE_REQUEST'
export const ALL_PRODUCT_TYPE_SUCCESS = 'ALL_PRODUCT_TYPE_SUCCESS'
export const ALL_PRODUCT_TYPE_FAIL = 'ALL_PRODUCT_TYPE_FAIL'

export const ALL_ATTRIBUTES_REQUEST = 'ALL_ATTRIBUTES_REQUEST'
export const ALL_ATTRIBUTES_SUCCESS = 'ALL_ATTRIBUTES_SUCCESS'
export const ALL_ATTRIBUTES_FAIL = 'ALL_ATTRIBUTES_FAIL'

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST'
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL'

export const ADD_COLOR_STONE_RESET = 'ADD_COLOR_STONE_RESET'
export const ADD_COLOR_STONE_REQUEST = 'ADD_COLOR_STONE_REQUEST'
export const ADD_COLOR_STONE_SUCCESS = 'ADD_COLOR_STONE_SUCCESS'
export const ADD_COLOR_STONE_FAIL = 'ADD_COLOR_STONE_FAIL'


export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const MESSAGE_TRANSFER_REQUEST = 'MESSAGE_TRANSFER_REQUEST'
export const MESSAGE_TRANSFER_SUCCESS = 'MESSAGE_TRANSFER_SUCCESS'
export const MESSAGE_TRANSFER_FAIL = 'MESSAGE_TRANSFER_FAIL'

export const MY_PRODUCT_REQUEST = 'MY_PRODUCT_REQUEST'
export const MY_PRODUCT_SUCCESS = 'MY_PRODUCT_SUCCESS'
export const MY_PRODUCT_FAIL = 'MY_PRODUCT_FAIL'

export const MY_PRODUCT_SELECTACTION_REQUEST = 'MY_PRODUCT_SELECTACTION_REQUEST'
export const MY_PRODUCT_SELECTACTION_SUCCESS = 'MY_PRODUCT_SELECTACTION_SUCCESS'
export const MY_PRODUCT_SELECTACTION_FAIL = 'MY_PRODUCT_SELECTACTION_FAIL'

export const MY_PRODUCT_ACTIVEALL_REQUEST = 'MY_PRODUCT_ACTIVEALL_REQUEST'
export const MY_PRODUCT_ACTIVEALL_SUCCESS = 'MY_PRODUCT_ACTIVEALL_SUCCESS'
export const MY_PRODUCT_ACTIVEALL_FAIL = 'MY_PRODUCT_ACTIVEALL_FAIL'

export const ADD_FAVORITE_REQUEST = 'ADD_FAVORITE_REQUEST'
export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS'
export const ADD_FAVORITE_FAIL = 'ADD_FAVORITE_FAIL'

export const GET_FAVORITE_REQUEST = 'GET_FAVORITE_REQUEST'
export const GET_FAVORITE_SUCCESS = 'GET_FAVORITE_SUCCESS'
export const GET_FAVORITE_FAIL = 'GET_FAVORITE_FAIL'

export const GET_FAVORITE_CATEGORY_REQUEST = 'GET_FAVORITE_CATEGORY_REQUEST'
export const GET_FAVORITE_CATEGORY_SUCCESS = 'GET_FAVORITE_CATEGORY_SUCCESS'
export const GET_FAVORITE_CATEGORY_FAIL = 'GET_FAVORITE_CATEGORY_FAIL'

export const GET_PRODUCT_COUNT_REQUEST = 'GET_PRODUCT_COUNT_REQUEST'
export const GET_PRODUCT_COUNT_SUCCESS = 'GET_PRODUCT_COUNT_SUCCESS'
export const GET_PRODUCT_COUNT_FAIL = 'GET_PRODUCT_COUNT_FAIL'

export const GET_MODEL_ATTRIBUTES_REQUEST = 'GET_MODEL_ATTRIBUTES_REQUEST'
export const GET_MODEL_ATTRIBUTES_SUCCESS = 'GET_MODEL_ATTRIBUTES_SUCCESS'
export const GET_MODEL_ATTRIBUTES_FAIL = 'GET_MODEL_ATTRIBUTES_FAIL'

export const GET_RATING_TAGS_REQUEST = 'GET_RATING_TAGS_REQUEST'
export const GET_RATING_TAGS_SUCCESS = 'GET_RATING_TAGS_SUCCESS'
export const GET_RATING_TAGS_FAIL = 'GET_RATING_TAGS_FAIL'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL'

export const UPDATE_WATCH_REQUEST = 'UPDATE_WATCH_REQUEST'
export const UPDATE_WATCH_SUCCESS = 'UPDATE_WATCH_SUCCESS'
export const UPDATE_WATCH_FAIL = 'UPDATE_WATCH_FAIL'

export const UPDATE_COLOR_STONE_RESET = 'UPDATE_COLOR_STONE_RESET'
export const UPDATE_COLOR_STONE_REQUEST = 'UPDATE_COLOR_STONE_REQUEST'
export const UPDATE_COLOR_STONE_SUCCESS = 'UPDATE_COLOR_STONE_SUCCESS'
export const UPDATE_COLOR_STONE_FAIL = 'UPDATE_COLOR_STONE_FAIL'

export const UPDATE_SINGLE_PRODUCT_REQUEST = 'UPDATE_SINGLE_PRODUCT_REQUEST'
export const UPDATE_SINGLE_PRODUCT_SUCCESS = 'UPDATE_SINGLE_PRODUCT_SUCCESS'
export const UPDATE_SINGLE_PRODUCT_FAIL = 'UPDATE_SINGLE_PRODUCT_FAIL'

export const ALL_SELLER_REQUEST = 'ALL_SELLER_REQUEST'
export const ALL_SELLER_SUCCESS = 'ALL_SELLER_SUCCESS'
export const ALL_SELLER_FAIL = 'ALL_SELLER_FAIL'

export const ADVERTISEMENT_REQUEST = 'ADVERTISEMENT_REQUEST'
export const ADVERTISEMENT_SUCCESS = 'ADVERTISEMENT_SUCCESS'
export const ADVERTISEMENT_FAIL = 'ADVERTISEMENT_FAIL'

export const Ad_PLAN_REQUEST = 'Ad_PLAN_REQUEST'
export const Ad_PLAN_SUCCESS = 'Ad_PLAN_SUCCESS'
export const Ad_PLAN_FAIL = 'Ad_PLAN_FAIL'


export const STRIPE_PAYMENT_REQUEST = 'STRIPE_PAYMENT_REQUEST'
export const STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS'
export const STRIPE_PAYMENT_FAIL = 'STRIPE_PAYMENT_FAIL'


export const CHECK_PLAN_REQUEST = 'CHECK_PLAN_REQUEST'
export const CHECK_PLAN_SUCCESS = 'CHECK_PLAN_SUCCESS'
export const CHECK_PLAN_FAIL = 'CHECK_PLAN_FAIL'
export const CHECK_PLAN_RESET = 'CHECK_PLAN_RESET'

export const ADVERTISE_PRODUCT_REQUEST = 'ADVERTISE_PRODUCT_REQUEST'
export const ADVERTISE_PRODUCT_SUCCESS = 'ADVERTISE_PRODUCT_SUCCESS'
export const ADVERTISE_PRODUCT_FAIL = 'ADVERTISE_PRODUCT_FAIL'

export const ADVERTISE_FREE_REQUEST = 'ADVERTISE_FREE_REQUEST'
export const ADVERTISE_FREE_SUCCESS = 'ADVERTISE_FREE_SUCCESS'
export const ADVERTISE_FREE_FAIL = 'ADVERTISE_FREE_FAIL'
export const ADVERTISE_FREE_RESET = 'ADVERTISE_FREE_RESET'

export const DELETE_ADVERTISE_REQUEST = 'DELETE_ADVERTISE_REQUEST'
export const DELETE_ADVERTISE_SUCCESS = 'DELETE_ADVERTISE_SUCCESS'
export const DELETE_ADVERTISE_FAIL = 'DELETE_ADVERTISE_FAIL'
export const DELETE_ADVERTISE_RESET = 'DELETE_ADVERTISE_RESET'

export const MY_PRODUCT_STATUS_REQUEST = 'MY_PRODUCT_STATUS_REQUEST'
export const MY_PRODUCT_STATUS_SUCCESS = 'MY_PRODUCT_STATUS_SUCCESS'
export const MY_PRODUCT_STATUS_FAIL = 'MY_PRODUCT_STATUS_FAIL'
