import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAttributes } from '../../../actions/productActions';
import { IMAGES } from '../../../constants';
import $ from 'jquery';
import { getAllProductsFilter } from '../../../actions/productActions';
import { useLocation } from 'react-router';
import { getLastURL, ShapeImageUrl } from '../../../helper';
import { CaratParcelsJson, ParcelMeasurementJson, seiveJson } from '../../../utils/json';
import { FormattedMessage, useIntl } from 'react-intl';
import mixpanel from 'mixpanel-browser';

const Sidebar = ({ tabChangeHandler }) => {


    const dispatch = useDispatch();
    const location = useLocation();

    const pathname = getLastURL(location);

    const { attributes } = useSelector(state => state.attributes);

    const [shape, setShape] = useState([]);
    const [clarityCheck, setClarityCheck] = useState(new Array(attributes && attributes.clarity && attributes.clarity.length).fill(false));
    const [clarity, setClarity] = useState([]);
    const [color, setColor] = useState([]);
    const [intensityCheck, setIntensityCheck] = useState(new Array(attributes && attributes.fancy_intensity && attributes.fancy_intensity.length).fill(false));
    const [fancy_color_intensity, setFancyColorIntensity] = useState([]);
    const [overtoneCheck, setOvertoneCheck] = useState(new Array(attributes && attributes.fancy_overtone && attributes.fancy_overtone.length).fill(false));
    const [fancy_color_overtone, setFancyColorOvertone] = useState([]);
    const [fancycolorCheck, setFancyColorCheck] = useState(new Array(attributes && attributes.fancy_color && attributes.fancy_color.length).fill(false));
    const [fancy_color, setFancyColor] = useState([]);
    const [selectColor, setSelectColor] = useState(false);
    const [price, setPrice] = useState({
        from: "",
        to: ""
    })
    const [seive, setSeive] = useState({
        from: "",
        to: ""
    })
    const [singleseiveCheck, setSingleSeiveCheck] = useState(new Array(seiveJson && seiveJson.length).fill(false));
    const [singleSeive, setSingleSeive] = useState([])
    const [carat, setCarat] = useState({
        from: "",
        to: ""
    })
    const [caratcheckboxCheck, setCaratCheckboxCheck] = useState(new Array(CaratParcelsJson && CaratParcelsJson.length).fill(false))
    const [measurementcheckboxCheck, setMeasurementCheckboxCheck] = useState(new Array(ParcelMeasurementJson && ParcelMeasurementJson.length).fill(false))
    const [measurement, setMeasurement] = useState({
        length: "",
        width: ""
    })
    const [supplierCheck, setSupplierCheck] = useState(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false));
    const [supplier, setSupplier] = useState([]);



    useEffect(() => {
        dispatch(getAttributes('parcels'))
    }, [dispatch])

    const loadMoreDefault = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr' ? 'Daha fazla gör' : 'See More';
        let see_less = localStorage.getItem('lang') == 'tr' ? 'Daha Az Gör' : 'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; ' + see_more)
        } else {
            $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; ' + see_less)
        }
    }

    const loadMoreShape = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr' ? 'Daha fazla gör' : 'See More';
        let see_less = localStorage.getItem('lang') == 'tr' ? 'Daha Az Gör' : 'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            // $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .c_hide').attr('style', 'display: none !important;')
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; ' + see_more)
        } else {
            // $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').attr('style', 'display: inline-block !important;')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; ' + see_less)
        }
    }





    const handleFilterShape = (id) => {
        if (!shape.includes(id)) {
            setShape([...shape, id]);
        } else {
            shape.splice(shape.indexOf(id), 1);
        }


        if ($('.shape-items-show li.shapec_' + id).hasClass('active')) {
            $('.shape-items-show li.shapec_' + id).removeClass('active')

        } else {
            $('.shape-items-show li.shapec_' + id).addClass('active')
        }
    }


    const handleFilterClarity = (position, id) => {
        const updatedClarity = clarityCheck && clarityCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setClarityCheck(updatedClarity);

        if (!clarity.includes(id)) {
            setClarity([...clarity, id]);
        } else {
            clarity.splice(clarity.indexOf(id), 1);
        }
    }


    const handleFilterIntensity = (position, id) => {
        const updatedIntensity = intensityCheck && intensityCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setIntensityCheck(updatedIntensity);

        if (!fancy_color_intensity.includes(id)) {
            setFancyColorIntensity([...fancy_color_intensity, id]);
        } else {
            fancy_color_intensity.splice(fancy_color_intensity.indexOf(id), 1);
        }
    }



    const handleFilterOvertone = (position, id) => {
        const updateOvertone = overtoneCheck && overtoneCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setOvertoneCheck(updateOvertone);

        if (!fancy_color_overtone.includes(id)) {
            setFancyColorOvertone([...fancy_color_overtone, id]);
        } else {
            fancy_color_overtone.splice(fancy_color_overtone.indexOf(id), 1);
        }
    }


    const handleFilterFancyColor = (position, id) => {
        const updateFancycolor = fancycolorCheck && fancycolorCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setFancyColorCheck(updateFancycolor);

        if (!fancy_color.includes(id)) {
            setFancyColor([...fancy_color, id]);
        } else {
            fancy_color.splice(fancy_color.indexOf(id), 1);
        }
    }

    const handleSelectColor = (e) => {
        setSelectColor(e.target.checked);
        dispatch(getAllProductsFilter(pathname, { is_fancy: e.target.checked }));
        if (e.target.checked) {
            $('#d_intensity').css({
                display: 'block'
            })
            $('#d_overtone').css({
                display: 'block'
            })
            $('#d_fancycolor').css({
                display: 'block'
            })
            $('#d_color').css({
                display: 'none'
            })
        } else {
            $('#d_intensity').css({
                display: 'none'
            })
            $('#d_overtone').css({
                display: 'none'
            })
            $('#d_fancycolor').css({
                display: 'none'
            })
            $('#d_color').css({
                display: 'block'
            })
        }
    }


    const handleFilterWhiteColor = (id) => {
        if (!color.includes(id)) {
            setColor([...color, id]);
        } else {
            color.splice(color.indexOf(id), 1);
        }


        if ($('.shape-items-show li.colorc_' + id).hasClass('active')) {
            $('.shape-items-show li.colorc_' + id).removeClass('active')

        } else {
            $('.shape-items-show li.colorc_' + id).addClass('active')
        }
    }

    const handleFilter = (e) => {
        e.preventDefault();
        mixpanel.track('Parcels_filter Jan25');
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname, { shapes: shape, clarity: clarity, color: color, fancy_color: fancy_color, fancy_intensity: fancy_color_intensity, fancy_overtone: fancy_color_overtone, sieve: seive, price: price, carat: carat, measurement: measurement, seller: supplier, sieves: singleSeive, is_fancy: selectColor }));
    }

    const handleFilterSeive = (e) => {
        setSeive({ ...seive, [e.target.name]: e.target.value });
    }

    const handlePrice = (event) => {
        setPrice({ ...price, [event.target.name]: event.target.value });
    }
    const handleCarat = (event) => {
        setCarat({ ...carat, [event.target.name]: event.target.value });
    }
    const handleMeasurement = (event) => {
        setMeasurement({ ...measurement, [event.target.name]: event.target.value });
    }

    const handleResetAll = () => {

        setShape([]);
        setClarityCheck(new Array(attributes && attributes.clarity && attributes.clarity.length).fill(false));
        setClarity([]);
        setColor([]);
        setIntensityCheck(new Array(attributes && attributes.fancy_intensity && attributes.fancy_intensity.length).fill(false));
        setFancyColorIntensity([]);
        setOvertoneCheck(new Array(attributes && attributes.fancy_overtone && attributes.fancy_overtone.length).fill(false));
        setFancyColorOvertone([]);
        setFancyColorCheck(new Array(attributes && attributes.fancy_color && attributes.fancy_color.length).fill(false));
        setFancyColor([]);
        setSingleSeiveCheck(new Array(seiveJson && seiveJson.length).fill(false));
        setSingleSeive([])
        setSelectColor(false);
        setCaratCheckboxCheck(new Array(CaratParcelsJson && CaratParcelsJson.length).fill(false))
        setSeive({
            from: "",
            to: ""
        });
        setPrice({
            from: "",
            to: ""
        })
        setCarat({
            from: "",
            to: ""
        })
        setMeasurement({
            length: "",
            width: ""
        })
        setSupplierCheck(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false))
        setSupplier([])
        setMeasurementCheckboxCheck(new Array(ParcelMeasurementJson && ParcelMeasurementJson.length).fill(false))
        if ($('.shape-items-show li').hasClass('active')) {
            $('.shape-items-show li').removeClass('active')
        }
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname, {}));
    }


    const handleFilterCaratCheckbox = (From, To, position) => {

        const updateCaratCheckbox = caratcheckboxCheck && caratcheckboxCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return false;
            }
        });

        setCaratCheckboxCheck(updateCaratCheckbox);


        if (updateCaratCheckbox[position]) {
            setCarat({
                from: From,
                to: To
            })
        } else {
            setCarat({
                from: '',
                to: ''
            })
        }
    }


    const handleFilterMeasurementCheckbox = (length, width, position) => {

        const updateMeasurementCheckbox = measurementcheckboxCheck && measurementcheckboxCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return false;
            }
        });

        setMeasurementCheckboxCheck(updateMeasurementCheckbox);

        if (updateMeasurementCheckbox[position]) {
            setMeasurement({
                length: length,
                width: width
            })
        } else {
            setMeasurement({
                length: '',
                width: ''
            })
        }
    }



    const handleFilterSingleSeive = (position, data) => {

        const updateSingleSeive = singleseiveCheck && singleseiveCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });

        setSingleSeiveCheck(updateSingleSeive);

        if (updateSingleSeive[position]) {
            setSingleSeive([...singleSeive, data])
        } else {
            singleSeive.splice(singleSeive.indexOf(data), 1);
        }
    }


    const handleSupplier = (position, id) => {
        const updatedSupplier = supplierCheck && supplierCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSupplierCheck(updatedSupplier);

        if (!supplier.includes(id)) {
            setSupplier([...supplier, id]);
        } else {
            supplier.splice(supplier.indexOf(id), 1);
        }
    }

    return (
        <div className="col-lg-3">
            <div className="dashboard-sidebar ">
                <form onSubmit={handleFilter}>
                    <div className="filter_list">
                        <div className="filter_side">


                            <div className="filter_top p_shape">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb page_head"><FormattedMessage id="category.parcels" defaultMessage="Parcels" /></h4>
                                    <p className="font-16 black-3B fm "><FormattedMessage id="addsingleproduct.shape" defaultMessage="Shape" /></p>
                                </div>
                                <ul id="myList" className="shape-items-show">
                                    {
                                        attributes && attributes?.shape && attributes.shape.map((shape, key) => {
                                            return (
                                                <li className={key < 8 ? 'c_show shapec_' + shape.id : 'c_hide shapec_' + shape.id} onClick={() => handleFilterShape(shape.id)}><img src={ShapeImageUrl(shape.image)} alt="" /><span>{shape.name}</span></li>
                                            )
                                        })
                                    }
                                </ul>
                                <p id="loadMore" onClick={(e) => loadMoreShape('p_shape')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>



                            <div className="filter_top mt-3 d_seive">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="Sieve" defaultMessage="Sieve" /></h4>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select className="form-control seive-select" value={seive.from} name="from" onChange={handleFilterSeive}>
                                                <option value="">{useIntl().formatMessage({ id: 'Any' })}</option>
                                                {
                                                    attributes && attributes?.sieve && attributes.sieve.map((data, key) => {
                                                        return (
                                                            <option value={data.id}>{data.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select className="form-control seive-select" value={seive.to} name="to" onChange={handleFilterSeive}>
                                                <option value="">{useIntl().formatMessage({ id: 'Any' })}</option>
                                                {
                                                    attributes && attributes?.sieve && attributes.sieve.map((data, key) => {
                                                        return (
                                                            <option value={data.id}>{data.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="multi_price">
                                    {
                                        seiveJson && seiveJson.map((data, key) => {
                                            return (
                                                <div className={key < 10 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.value} checked={singleseiveCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterSingleSeive(key, data.value)} />
                                                    <label htmlFor={data.id}>{data.display_name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>



                            <div className="filter_top">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.measurement" defaultMessage="Measurement" /></h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" placeholder={useIntl().formatMessage({ id: 'length' })} value={measurement.length} name="length" onChange={handleMeasurement} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={measurement.width} name="width" onChange={handleMeasurement} placeholder={useIntl().formatMessage({ id: 'width' })} />
                                    </div>
                                </div>

                                <div className="filter_top mt-3 d_measurement_checkbox">
                                    <div className="multi_price">
                                        {
                                            ParcelMeasurementJson && ParcelMeasurementJson.map((data, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={data.display_name} checked={measurementcheckboxCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterMeasurementCheckbox(data.length, data.width, key)} />
                                                        <label htmlFor={data.id}>{data.display_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/* <p id="loadMore" onClick={(e) => loadMoreDefault('d_measurement_checkbox')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; See More</p> */}
                                </div>
                            </div>




                            <div className="filter_top">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat" /></h4>
                                </div>

                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" value={carat.from} placeholder={useIntl().formatMessage({ id: 'Low' })} name="from" onChange={handleCarat} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" value={carat.to} className="form-control" name="to" onChange={handleCarat} placeholder={useIntl().formatMessage({ id: 'High' })} />
                                    </div>
                                </div>

                                <div className="filter_top mt-3 d_carat_checkbox">
                                    <div className="multi_price">
                                        {
                                            CaratParcelsJson && CaratParcelsJson.map((data, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={data.display_name} checked={caratcheckboxCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterCaratCheckbox(data.from, data.to, key)} />
                                                        <label htmlFor={data.id}>{data.display_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/* <p id="loadMore" onClick={(e) => loadMoreDefault('d_carat_checkbox')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; See More</p> */}
                                </div>

                            </div>



                            <div className="filter_top mt-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.color" defaultMessage="Color" /></h4>
                                </div>
                                <div className="cus_switch d-flex align-item-center">
                                    <p className="font-14 fm black-3B pr-3"><FormattedMessage id="addsingleproduct.White" defaultMessage="White" /></p>
                                    <label className="switch">
                                        <input type="checkbox" checked={selectColor} onChange={handleSelectColor} />
                                        <span className="slider round"></span>
                                    </label>
                                    <p className="gray-75 font-14 fm pl-3"><FormattedMessage id="addsingleproduct.Fancy" defaultMessage="Fancy" /></p>
                                </div>
                            </div>


                            <div className="filter_top d_color mt-3" id="d_color" style={{ display: 'block' }}>
                                <ul id="myList" className="shape-items-show">
                                    {
                                        attributes && attributes?.color && attributes.color.map((color, key) => {
                                            return (
                                                <li className={key < 12 ? 'c_show colorc_' + color.id : 'c_hide colorc_' + color.id} onClick={() => handleFilterWhiteColor(color.id)}><span>{color.name}</span></li>
                                            )
                                        })
                                    }
                                </ul>
                                <p id="loadMore" onClick={(e) => loadMoreShape('d_color')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>


                            <div className="filter_top mt-3 d_intensity" id="d_intensity" style={{ display: 'none' }}>
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.finishcolorintensity" defaultMessage="Fancy Intensity" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.fancy_intensity && attributes.fancy_intensity.map((data, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={intensityCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterIntensity(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_intensity')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>



                            <div className="filter_top mt-3 d_overtone" id="d_overtone" style={{ display: 'none' }}>
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.finishcolorovertone" defaultMessage="Fancy Overtone" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.fancy_overtone && attributes.fancy_overtone.map((data, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={overtoneCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterOvertone(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_overtone')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>



                            <div className="filter_top mt-3 d_fancycolor" id="d_fancycolor" style={{ display: 'none' }}>
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.finishcolor" defaultMessage="Fancy Color" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.fancy_color && attributes.fancy_color.map((data, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={fancycolorCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterFancyColor(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_fancycolor')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>



                            <div className="filter_top mt-3 p_clarity">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.clarity" defaultMessage="Clarity" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.clarity && attributes.clarity.map((data, key) => {
                                            return (
                                                <div className={key < 8 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={clarityCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterClarity(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('p_clarity')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>


                            <div className="filter_top mb-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.price-ct" defaultMessage="Price/ct" /></h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" placeholder={useIntl().formatMessage({ id: 'Low' })} value={price.from} name="from" onChange={handlePrice} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={price.to} name="to" onChange={handlePrice} placeholder={useIntl().formatMessage({ id: 'High' })} />
                                    </div>
                                </div>
                            </div>



                            <div className="filter_top mt-3  d_seller">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="Supplier" defaultMessage="Supplier" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.top_sellers && attributes.top_sellers.map((data, key) => {
                                            return (
                                                <div className={key < 20 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={supplierCheck[key]} id={data.id} className="checkbox" onChange={() => handleSupplier(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name} ({data.product_count})</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_seller')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>




                        </div>
                        <div className="filter_top">
                            <div className="filter_btn">
                                <button className="btn btn-black" type="submit" style={{ minWidth: "96%" }}>
                                    <FormattedMessage id="ApplyFilters" defaultMessage="Apply filters" />
                                </button>
                                <p className="font-14 gray-65 text-center mt-3" style={{ cursor: 'pointer' }} onClick={handleResetAll}><FormattedMessage id="Reset_all" defaultMessage="Reset all" /> <span className="icon-close_1"></span></p>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default Sidebar
