export const GET_CHATUSER_REQUEST = 'GET_CHATUSER_REQUEST'
export const GET_CHATUSER_SUCCESS = 'GET_CHATUSER_SUCCESS'
export const GET_CHATUSER_FAIL = 'GET_CHATUSER_FAIL'

export const GET_MESSAGE_HISTORY_REQUEST = 'GET_MESSAGE_HISTORY_REQUEST'
export const GET_MESSAGE_HISTORY_SUCCESS = 'GET_MESSAGE_HISTORY_SUCCESS'
export const GET_MESSAGE_HISTORY_FAIL = 'GET_MESSAGE_HISTORY_FAIL'

export const ADD_CHATUSER_REQUEST = 'ADD_CHATUSER_REQUEST'
export const ADD_CHATUSER_SUCCESS = 'ADD_CHATUSER_SUCCESS'
export const ADD_CHATUSER_FAIL = 'ADD_CHATUSER_FAIL'

export const GET_SHORTMSG_REQUEST = 'GET_SHORTMSG_REQUEST'
export const GET_SHORTMSG_SUCCESS = 'GET_SHORTMSG_SUCCESS'
export const GET_SHORTMSG_FAIL = 'GET_SHORTMSG_FAIL'

export const CHAT_ACTIVE_STATUS_REQUEST = 'CHAT_ACTIVE_STATUS_REQUEST'
export const CHAT_ACTIVE_STATUS_SUCCESS = 'CHAT_ACTIVE_STATUS_SUCCESS'
export const CHAT_ACTIVE_STATUS_FAIL = 'CHAT_ACTIVE_STATUS_FAIL'

