import { 
    GET_CHATUSER_REQUEST, 
    GET_CHATUSER_SUCCESS, 
    GET_CHATUSER_FAIL,
    GET_MESSAGE_HISTORY_REQUEST,
    GET_MESSAGE_HISTORY_SUCCESS,
    GET_MESSAGE_HISTORY_FAIL,
    ADD_CHATUSER_REQUEST,
    ADD_CHATUSER_SUCCESS,
    ADD_CHATUSER_FAIL,
    GET_SHORTMSG_REQUEST,
    GET_SHORTMSG_SUCCESS,
    GET_SHORTMSG_FAIL,

} from "../constants/chatConstant";
import { decryptData, encryptData } from "../helper";
import { makeRequestGet, makeRequestPost} from "../utils/apiHelper";
import {socket} from "../utils/socket";


export const getChatUserAction = () => async (dispatch) =>{
    try {
        dispatch({ type: GET_CHATUSER_REQUEST })
        socket.connected = true;
        // socket.disconnected = false;

        var data = { 
            user_id: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).id,
        }
            try{
                socket.emit('inbox_list', {});
            }catch(error){
                console.log('inbox_listinbox_listinbox_list',error);
            }
        socket.on('inbox_list_response', res =>{
            const new_res = decryptData(res);
            if(new_res && new_res.length > 0)
            {
                dispatch({
                 type: GET_CHATUSER_SUCCESS,
                 payload:new_res
                })  
            }
            else{
                dispatch({
                  type: GET_CHATUSER_FAIL,
                  payload: {}
                })
            }
       })

    } catch (error) {
        dispatch({
            type: GET_CHATUSER_FAIL,
            payload: error.response
        })

    }
}

export const getMessageHistoryAction = (user_id, other_user_id) => async (dispatch) =>{
    try {
        dispatch({ type: GET_MESSAGE_HISTORY_REQUEST })

        socket.connected = true;
        // socket.disconnected = false;

        var data = {
            other_user_id: other_user_id,
            page:1,
            limit:1000
        }
       socket.emit('get_message_history', encryptData(data));
       socket.on('get_message_history_response', res =>{
        const new_res = decryptData(res);
        console.log('new_res======', new_res);
        if(new_res && new_res.status)
        {
            dispatch({
             type: GET_MESSAGE_HISTORY_SUCCESS,
             payload:new_res.data
            })  
        }
        else{
            dispatch({
              type: GET_MESSAGE_HISTORY_FAIL,
              payload: new_res.message
            })
        }
       })

    } catch (error) {
        dispatch({
            type: GET_MESSAGE_HISTORY_FAIL,
            payload: error.response
        })
    }
}


export const addChatUserAction = (chatuserid) => async (dispatch) =>{

    try {
        dispatch({ type:  ADD_CHATUSER_REQUEST })
        const data = await makeRequestPost('add-chat-users', {chatuserid});
        if (data.status) {
            dispatch({
                type:  ADD_CHATUSER_SUCCESS,
                payload:data.message
            })
        }
        else {
            dispatch({
                type:  ADD_CHATUSER_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type:  ADD_CHATUSER_FAIL,
            payload: error.response
        })
    }
}


export const getshortMsg = () => async (dispatch) =>{
    try {
        dispatch({ type:  GET_SHORTMSG_REQUEST })
        const data = await makeRequestPost('shortcut-msgs');
        if (data.status) {
            dispatch({
                type:  GET_SHORTMSG_SUCCESS,
                payload:data.data
            })
        }
        else {
            dispatch({
                type: GET_SHORTMSG_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type:  GET_SHORTMSG_FAIL,
            payload: error.response
        })
    }
}