export const GET_HOMEPAGE_REQUEST = 'GET_HOMEPAGE_REQUEST'
export const GET_HOMEPAGE_SUCCESS = 'GET_HOMEPAGE_SUCCESS'
export const GET_HOMEPAGE_FAIL = 'GET_HOMEPAGE_FAIL'

export const TERM_CONDITION_REQUEST = 'TERM_CONDITION_REQUEST'
export const TERM_CONDITION_SUCCESS = 'TERM_CONDITION_SUCCESS'
export const TERM_CONDITION_FAIL = 'TERM_CONDITION_FAIL'

export const POLICY_REQUEST = 'POLICY_REQUEST'
export const POLICY_SUCCESS = 'POLICY_SUCCESS'
export const POLICY_FAIL = 'POLICY_FAIL'

export const ELECTRONIC_MESSAGE_REQUEST = 'ELECTRONIC_MESSAGE_REQUEST'
export const ELECTRONIC_MESSAGE_SUCCESS = 'ELECTRONIC_MESSAGE_SUCCESS'
export const ELECTRONIC_MESSAGE_FAIL = 'ELECTRONIC_MESSAGE_FAIL'