import React, { useEffect, useState, Fragment } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../layout/Loader';
import MetaData from '../../layout/MetaData';
import { register, clearError, getCountry } from '../../../actions/userActions';
import { JEWELER_ROLE, SUPPLIER_ROLE } from '../../../constants';
import { IMAGES } from '../../../constants';
import { url } from '../../../config';
import { validateFields } from '../../../validation';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import mixpanel, { alias } from 'mixpanel-browser';
import { getParamsByName } from '../../../helper';


const Register = ({ history, location }) => {
    var type = getParamsByName(window.location.href, 't')
    type = type ? type : 1;
    const [userRegistration, setuserRegistration] = useState({
        email: '',
        password: '',
        name: '',
        confirm_password: '',
        role: type,
        device_type: "WEB",
        device_token: "12345678",
        country: ''
    })
    const [validerror, setValidError] = useState({
        emailError: '',
        passwordError: '',
        nameError: '',
        confirm_passwordError: ''
    })
    const [loadingpage, setLoding] = useState({
        loadingpage: false
    })
    const [countryError, setCountryError] = useState('')

    const alert = useAlert();
    const dispatch = useDispatch();

    const { isAuthenticated, error, loading } = useSelector(state => state.user)
    const { user } = useSelector(state => state.user)
    const country = useSelector(state => state.country);

    useEffect(() => {
        dispatch(getCountry());
    }, [])

    useEffect(() => {
        if (user && user.is_business_step === 1 && user.is_billing_step === 1) {
            history.push(url)
            return
        }

        if (isAuthenticated) {
            history.push({ pathname: url + '/business-info' })
            return
        }

        if (error) {
            dispatch(clearError());
            alert.error(error)
        }
    }, [dispatch, error, isAuthenticated, history, user, alert])

    const handleInputChange = (event) => {
        setuserRegistration({ ...userRegistration, [event.target.name]: event.target.value })
    }

    const handleRole = (role) => {
        setValidError({
            emailError: '',
            passwordError: '',
            nameError: '',
            confirm_passwordError: ''
        })
        setuserRegistration({
            ...userRegistration,
            email: '',
            password: '',
            name: '',
            confirm_password: '',
            role: role
        })
        setLoding({
            loadingpage: true
        })
        setTimeout(() => {
            setLoding({
                loadingpage: false
            })
        }, 100);
    }

    const handleShowPassword = (id) => {
        let x = document.getElementById(id);
        if (x.type === "password") { x.type = "text" } else { x.type = "password" }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const nameError = validateFields.validateName(userRegistration.name);
        const emailError = validateFields.validateEmail(userRegistration.email);
        const passwordError = validateFields.validatePassword(userRegistration.password);
        const confirm_passwordError = validateFields.validateConfirmPassword(userRegistration.password, userRegistration.confirm_password);

        if (userRegistration?.country === "") {
            setCountryError(true)
            // alert.error("Please select country")
        } else {
            setCountryError(false)
        }

        if ([nameError, emailError, passwordError, confirm_passwordError].every(e => e === false)) {
            setValidError({
                ...validerror, nameError, emailError, passwordError, confirm_passwordError
            })

            dispatch(register(userRegistration));
        }
        else {
            setValidError({
                ...validerror, nameError, emailError, passwordError, confirm_passwordError
            })
        }
    }

    useEffect(() => {
        mixpanel.track('Singup')
    }, [])

    const handleSelectCountry = (e) => {
        setuserRegistration({ ...userRegistration, country: e?.target?.value })
    }
console.log("country>>", country);

    return (
        <Fragment>
            {(loading || loadingpage.loadingpage) && <Loader />}
            <Fragment>
                <MetaData title={`registration`} />
                <div className=" top top_1">
                    <div className="container-fluidd">
                        <div className="row m-0">
                            <div className="col-md-12">
                                <div className="logo mb-5 text-center">
                                    <Link to={url}><img src={IMAGES.LOGO} alt="" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 justify-content-center">
                            <div className="col-lg-5 col-md-6 ">
                                <div className="reg_left">
                                    <div className="f_head">
                                        <h4 className="font-20 black-3B fb mb-4">
                                            <FormattedMessage
                                                id="register.become-a-member"
                                                defaultMessage="Become a member"
                                            />
                                        </h4>
                                        <p>
                                            <FormattedMessage
                                                id="register.already-have-an-account"
                                                defaultMessage="Already have an account?"
                                            />
                                            <Link to={url + '/login'}>
                                                <FormattedMessage
                                                    id="login.login"
                                                    defaultMessage="Log In"
                                                />
                                            </Link></p>
                                    </div>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        {
                                            userRegistration.role === 1 ?
                                                <Fragment>
                                                    <li className="nav-item">
                                                        <a style={{ cursor: 'pointer' }} className="nav-link active" id="home-tab" data-toggle="tab" onClick={(e) => handleRole(1)} role="tab" aria-controls="home" aria-selected="true">
                                                            <FormattedMessage
                                                                id="register.jeweler"
                                                                defaultMessage="JEWELER"
                                                            />
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a style={{ cursor: 'pointer' }} className="nav-link" id="profile-tab" data-toggle="tab" onClick={(e) => handleRole(2)} role="tab" aria-controls="profile" aria-selected="false">
                                                            <FormattedMessage
                                                                id="register.supplier"
                                                                defaultMessage="SUPPPLIER"
                                                            />
                                                        </a>
                                                    </li>
                                                </Fragment> :
                                                <Fragment>
                                                    <li className="nav-item">
                                                        <a style={{ cursor: 'pointer' }} className="nav-link" id="home-tab" data-toggle="tab" onClick={(e) => handleRole(1)} role="tab" aria-controls="home" aria-selected="true">
                                                            <FormattedMessage
                                                                id="register.jeweler"
                                                                defaultMessage="JEWELER"
                                                            />
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a style={{ cursor: 'pointer' }} className="nav-link active" id="profile-tab" data-toggle="tab" onClick={(e) => handleRole(2)} role="tab" aria-controls="profile" aria-selected="false">
                                                            <FormattedMessage
                                                                id="register.supplier"
                                                                defaultMessage="SUPPPLIER"
                                                            />
                                                        </a>
                                                    </li>
                                                </Fragment>
                                        }
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div className="jer_reg_form">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label htmlFor="">
                                                                    <FormattedMessage
                                                                        id="register.fullname"
                                                                        defaultMessage="Full Name"
                                                                    />
                                                                </label>
                                                                <input type="text" placeholder={useIntl().formatMessage({ id: 'Enter Name' })} className={classnames("form-control", { 'is-valid': validerror.nameError === false }, { 'is-invalid': validerror.nameError })} name="name" value={userRegistration.name} onChange={handleInputChange} />
                                                                <p className="invalid-feedback">{validerror.nameError}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label htmlFor="">
                                                                    <FormattedMessage
                                                                        id="login.email-address"
                                                                        defaultMessage="Email address"
                                                                    />
                                                                </label>
                                                                <input type="text" placeholder={useIntl().formatMessage({ id: 'Enter Email' })} className={classnames("form-control", { 'is-valid': validerror.emailError === false }, { 'is-invalid': validerror.emailError })} name="email" value={userRegistration.email} onChange={handleInputChange} />
                                                                <p className="invalid-feedback">{validerror.emailError}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label htmlFor="">
                                                                    <FormattedMessage
                                                                        id="login.password"
                                                                        defaultMessage="Password"
                                                                    />
                                                                </label>
                                                                <input type="password" id="jw-pass" placeholder={useIntl().formatMessage({ id: 'Enter Password' })} className={classnames("form-control", { 'is-valid': validerror.passwordError === false }, { 'is-invalid': validerror.passwordError })} name="password" value={userRegistration.password} onChange={handleInputChange} />
                                                                <span className="input_icon" onClick={(e) => handleShowPassword('jw-pass')}><img src={IMAGES.VIEW_SVG} alt="" /></span>
                                                                <p className="invalid-feedback">{validerror.passwordError}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label htmlFor="">
                                                                    <FormattedMessage
                                                                        id="register.repeat-password"
                                                                        defaultMessage="Repeat Password"
                                                                    />
                                                                </label>
                                                                <input type="password" id="jw-re-pass" placeholder={useIntl().formatMessage({ id: 'Enter Password' })} className={classnames("form-control", { 'is-valid': validerror.confirm_passwordError === false }, { 'is-invalid': validerror.confirm_passwordError })} name="confirm_password" value={userRegistration.confirm_password} onChange={handleInputChange} />
                                                                <span className="input_icon" onClick={(e) => handleShowPassword('jw-re-pass')}><img src={IMAGES.VIEW_SVG} alt="" /></span>
                                                                <p className="invalid-feedback">{validerror.confirm_passwordError}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label htmlFor="">
                                                                    <FormattedMessage
                                                                        id="selectCountry"
                                                                        defaultMessage="Select Country"
                                                                    />
                                                                </label>
                                                                <select name="countryName" className={classnames("form-control", { 'is-valid': countryError === false }, { 'is-invalid': countryError === true })} value={userRegistration?.country} onChange={handleSelectCountry}>
                                                                    <option value="">{localStorage.getItem("lang") === "en" ? "Select Country" : "Ülke Seç"}</option>
                                                                    {
                                                                        country && country?.country && country?.country?.countries?.map((country, key) => {
                                                                            return (
                                                                                <option key={key} value={country.id}>{country.name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <div className="form-group login_btn d-flex align-items-center mt-4">
                                                                <button type="submit" className="btn-lg register-btn w-100">
                                                                    <FormattedMessage
                                                                        id="register.continue"
                                                                        defaultMessage="Continue"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link to={url} className="font-16 black-3B text-center d-block  mt-4" style={{ fontWeight: 600 }}>
                                    <FormattedMessage
                                        id="main.header.contactsales"
                                        defaultMessage="Contact Sales"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </Fragment>
    )
}
export default Register
