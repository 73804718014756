import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store";
import { positions, transitions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import Wrapper from './components/Wrapper';
import { HelmetProvider } from 'react-helmet-async';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe('pk_test_51QyVHbREBUgxJYcI13u3D220NogChGB4QeQ1gCPCFUowqV3cQGMeKB6xfIDCCPjHPKcZnrrlsQgeQdkHVYc2yfLx00zrsSc1pc'); //demo
 const stripePromise = loadStripe('pk_live_51MWDPnKfZaUUosWKLwb7b4DKd1Aa6Y0d50SQIM7fS0HVOsfMWLAjVQDSuu4O9rbA9vWZqp1bqKXEsrSJ3om6PTfM009qhi5bF6'); //live

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
  transition: transitions.SCALE,

}

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
          <Wrapper>
            <HelmetProvider>
              < App />
            </HelmetProvider>
          </Wrapper>
        </AlertProvider>
      </Provider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
