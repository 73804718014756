import React, { Fragment, useState, useEffect } from 'react';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { clearError, contactUs } from '../../actions/userActions';
import { validateFields } from '../../validation';
const ContactUsPopUp = ({ handleResForm }) => {
    const alert = useAlert()
    const dispatch = useDispatch()
    const { contactus, error } = useSelector(state => state.contact)
    useEffect(() => {
        //alert.success('sdasdasd');

        if (contactus) {
            handleResForm()
            alert.success(contactus);
            dispatch(clearError());
            return
        }

        if (error) {

            alert.error(error)
            dispatch(clearError());
            handleResForm()
            return
        }

    }, [dispatch, error, contactus])
    const [contactInfo, setContactInfo] = useState({
        purpose_id: "",
        message: "",
        name: "",
        email: "",
        phone_number: ""
    })

    const [contactError, setContactError] = useState({
        purposeError: '',
        phoneError: '',
        nameError: '',
        emailError: '',
        messageError: ''
    })

    const handleContact = (event) => {
        setContactInfo({ ...contactInfo, [event.target.name]: event.target.value })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        //  return
        const phoneError = validateFields.validateAny(contactInfo.phone_number, 'Phone Number');
        const purposeError = validateFields.validatePurpose(contactInfo.purpose_id);
        const nameError = validateFields.validateAny(contactInfo.name, 'Name');
        const emailError = validateFields.validateAny(contactInfo.email, 'Email');
        const messageError = validateFields.validateMessage(contactInfo.message);
        if ([phoneError, nameError, messageError].every(e => e === false)) {
            setContactError({
                ...contactError, phoneError, messageError, nameError, purposeError
            })
            dispatch(contactUs(contactInfo))
        }
        else {
            setContactError({
                ...contactError, phoneError, messageError, nameError, purposeError
            })
        }
    }
    const handlBtnClick = (status) => {
        handleResForm(status);

    }
    const handleCancel = () => {
        setContactInfo({
            purpose_id: '',
            name: "",
            phone_number: "",
            email: "",
            message: ''
        })
    }
    return (
        <Fragment>
            <div className="modal fade PopsData" id="contact-us" tabindex="-1" role="dialog" aria-labelledby="comment" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <div className="succes">

                                <h3><FormattedMessage id="myaccount.contactus" /> </h3>
                                <hr />
                                <button id="dismiss-modal" type="button" className="close m-0" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <form onSubmit={(e) => handleSubmit(e)} method="post">
                                    <div className="row">

                                        <div className="col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor=""><FormattedMessage id="ChooseATopic" /> <span className="strike_red">*</span></label>
                                                <select name="purpose_id" onChange={handleContact} value={contactInfo.purpose_id} className={classnames("form-control", { 'is-valid': contactError.purposeError === false }, { 'is-invalid': contactError.purposeError })} >
                                                    <option value="">{useIntl().formatMessage({ id: 'AllCategories' })}</option>
                                                    <option value={1}>{useIntl().formatMessage({ id: 'Memberships' })}</option>
                                                    <option value={2}>{useIntl().formatMessage({ id: 'Help&Support' })}</option>
                                                </select>
                                                <p className="invalid-feedback">{contactError.purposeError}</p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor=""><FormattedMessage id="register.fullname" /> <span className="strike_red">*</span></label>
                                                <input name="name" maxLength={25} placeholder={useIntl().formatMessage({ id: 'Name' })} className={classnames("form-control", { 'is-valid': contactError.nameError === false }, { 'is-invalid': contactError.nameError })} value={contactInfo.name} onChange={handleContact} ></input>
                                                <p className="invalid-feedback">{contactError.nameError}</p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor=""><FormattedMessage id="Email" /> <span className="strike_red">*</span></label>
                                                <input type="email" name="email" maxLength={50} placeholder={useIntl().formatMessage({ id: 'Email' })} className={classnames("form-control", { 'is-valid': contactError.emailError === false }, { 'is-invalid': contactError.emailError })} value={contactInfo.email} onChange={handleContact} ></input>
                                                <p className="invalid-feedback">{contactError.emailError}</p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor=""><FormattedMessage id="Number" /> <span className="strike_red">*</span></label>
                                                <input name="phone_number" maxLength={11} pattern="[0-9]+" title="Allowed only 10 digits number" placeholder={useIntl().formatMessage({ id: 'Phone' })} className={classnames("form-control", { 'is-valid': contactError.phoneError === false }, { 'is-invalid': contactError.phoneError })} value={contactInfo.phone_number} onChange={handleContact} ></input>
                                                <p className="invalid-feedback">{contactError.phoneError}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-12">

                                            <div className="form-group">
                                                <label htmlFor=""><FormattedMessage id="Message" /> <span className="strike_red">*</span></label>
                                                <textarea name="message" rows="6" placeholder={useIntl().formatMessage({ id: 'Write a message' })} className={classnames("form-control", { 'is-valid': contactError.messageError === false }, { 'is-invalid': contactError.messageError })} value={contactInfo.message} onChange={handleContact} ></textarea>
                                                <p className="invalid-feedback">{contactError.messageError}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row actions my-5">
                                        <div className="col-md-11 col-lg-6">
                                            <button type="button" onClick={handleCancel} className="btns outline b-op"><FormattedMessage id="addsingleproduct.clear" /></button>
                                            <button type="submit" className="btns mr-3 sndBtn"><FormattedMessage id="submit" /></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
}

export default ContactUsPopUp

