import React, { Fragment, useState, useEffect, useRef } from 'react';
import { IMAGES } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from 'react-avatar';
import { useLocation } from 'react-router-dom';

import { convertLocaleTime, convertLocaleDate, scrollToBottomChat, convertLocaleMomentDate, encryptData, decryptData } from '../../../helper';
import Welcome from './Welcome';
import $ from 'jquery';
import { socket } from '../../../utils/socket';
import { useAlert } from 'react-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import validator from 'validator';
import { url, IMAGE_BASE_URL } from '../../../config';
import { getshortMsg } from '../../../actions/chatAction';

const Chatbox = (props) => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const [message, setMessage] = useState();
    const user_id = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).id && JSON.parse(sessionStorage.getItem('user')).id;
    const user_name = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).name;
    const profile_picture_full_thumb = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).profile_picture_full_thumb;
    const other_user_id = sessionStorage.getItem('other_user_id');
    const conversationId = sessionStorage.getItem('socket_group_id');
    const other_user_name = sessionStorage.getItem('other_user_name');
    const other_profile_picture_full_thumb = sessionStorage.getItem('other_profile_picture_full_thumb');
    const { messageHistory } = useSelector(state => state.messageHistory);
    const { shortmsg } = useSelector(state => state.shortmsg);
    const { active_status } = useSelector(state => state.ChatActiveState);
    console.log('active_status',active_status);
    console.log('messageHistory',messageHistory);
    
    const location = useLocation();

    useEffect(() => {
        dispatch(getshortMsg());
    }, [dispatch]);

    console.log("urlurlurlurlurlurlurlurlurlurlurlurlurl=====", location?.pathname);
    // const MessaageTransfer = useSelector(state => state.MessaageTransferState);
    const processText = (text) => {
        const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        const parts = text.split(urlPattern).map((part, index) => {
            if (urlPattern.test(part)) {
                return (
                    <a key={index} href={part.startsWith('www') ? `http://${part}` : part} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            }
            return part;
        });
        return parts;
    };

    const handleSendMessage = () => {
        setIsMsgRead(false);
        if (!message || message === undefined || message.trim().length <= 0) {
            alert.error('Message is required');
            return 0;
        }

        if (socket.connected === false) {
            socket.connected = true;
        }
        var data = {
            other_user_id: other_user_id,
            message: message,
            type: 'TEXT'
        }
        socket.emit('send_message', encryptData(data));
        setMessage('')
        socket.emit('total_message_count', encryptData({
            user_id: other_user_id,
        }));

        socket.emit('total_message_count', {});
        scrollToBottomChat();
    }

    const handleSendMessageKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
        else {
            return
        }
    }

    useEffect(() => {
        if (messageHistory) {
            scrollToBottomChat();
        }
    }, [messageHistory])

    setInterval(() => {
        socket.connected = true;
        // socket.disconnected = false;
    }, 3000)

    const [isMsgRead, setIsMsgRead] = useState(false)

    socket.off('is_deliver_listener').on('is_deliver_listener', (res) => {
        const read_res = decryptData(res);
        console.log("is_deliver_listener data", read_res);

        if (read_res?.status) {
            if (read_res?.data?.other_user_id) {
                console.log('is_deliver_listener user_id', read_res?.data?.other_user_id);
                if (user_id != read_res?.data?.other_user_id) {
                    read_res?.data?.id?.map((data) => {
                        // if (Number(user_id) == Number(read_res?.data?.other_user_id)) {
                            $(`#message${data}`).removeClass('fa-check');
                            $(`#message${data}`).addClass('fa-solid fa-check-double')
                        // }
                    })
                }

                // read_res?.data?.id?.map((data) => {
                //     if (Number(user_id) == Number(read_res?.data?.other_user_id)) {
                //         $(`#message${data}`).removeClass('fa-check');
                //         $(`#message${data}`).addClass('fa-solid fa-check-double')
                //     }
                // })
            }
        }
    });
    
    socket.off('receive_read_message').on('receive_read_message', (res) => {
        const read_res = decryptData(res);
        console.log("receive_read_message data", read_res);

        if (read_res?.status) {
            setIsMsgRead(true);
            if (read_res?.data?.new_ids?.length) {
                console.log('user_id', user_id);
                read_res?.data?.new_ids?.map((data) => {
                    if (Number(user_id) == Number(read_res?.data?.other_user_id)) {
                        $(`#message${data}`).removeClass('fa-check');
                        $(`#message${data}`).addClass('fa-solid fa-check-double class-blue')
                    }
                })
            }
        }
    });

    console.log('isMsgRead: ', isMsgRead);
    socket.off('receive_message').on('receive_message', res => {
        const new_res = decryptData(res)        
        console.log('res==============', new_res);
        if (conversationId != undefined || conversationId != "" || !location?.pathname?.includes('message') || active_status == undefined || active_status == 'undefined') {
            if (conversationId !== new_res?.group_id || !location?.pathname?.includes('message')) {
                return
            }
        }
        console.log('other_user_id==============', other_user_id);
        console.log('new_res?.user_id==============', new_res?.user_id);
        console.log('location?.pathname?.includes(msg)===========', location?.pathname?.includes('message'));
        if (other_user_id == new_res?.user_id && location?.pathname?.includes('message')) {
            console.log('new_res?.user_id==============', new_res?.user_id);
            var read = { other_user_id: new_res?.user_id, ids: [new_res?.id], type: "msg_read" };
            console.log("read_message emit hua hau");
            socket.emit('read_message', encryptData(read));
        }

        var imageee = (user_id === new_res?.user_id) ? (profile_picture_full_thumb || IMAGES.IMAGE_01) :
            (new_res?.other_profile_pic || IMAGES.IMAGE_01);

        var alignClass = (user_id === new_res?.user_id) ? 'u-messgae' : 'ownCmnt';

        const statusIcon = (new_res?.is_read === 1 || isMsgRead)
            ? `<i class="fa-solid fa-check-double text-right font-14 class-blue"  id=${"message" + new_res.id}></i>`
            : new_res?.is_read === 0 && new_res?.is_delivered === 0
                ? `<i class="fa fa-check text-right font-14" aria-hidden="true" id=${"message" + new_res.id}></i>`
                : new_res?.is_read === 0 && new_res?.is_delivered === 1
                    ? `<i class="fa-solid fa-check-double text-right font-14" id=${"message" + new_res.id}></i>`
                    : '';

        if (new_res?.message_type == 'TEXT') {
            const messageContent = validator.isURL(new_res.message)
                ? `<a href="${processText(new_res.message)}" target="_blank">
                    <p>${processText(new_res.message)}</p>
               </a>`
                : `<p>${processText(new_res.message)}</p>`;

            var varhtml = `<div class="d-flex my-4 align-content-end ${alignClass} mesgs">
                <div class="d-flex imgs"></div>
                <div class="m_t">
                    <div class="txts">${messageContent}</div>
                    <div class="d-flex dats" >
                        <p>${convertLocaleMomentDate(Date.now())} ${alignClass == 'u-messgae' ? statusIcon : ""}</p>
                    </div>
                </div>
            </div>`;

        } else if (new_res?.message_type == 'image') {
            var imgObj = JSON.parse(new_res.attachment_url)
            var varhtml = `<div class="d-flex my-4 align-content-end ownCmnt mesgs ${alignClass} mesgs">
                                <div class="d-flex  align-items-center txts">
                                    <div class="s-i-image">
                                        <LightGallery plugins={[lgThumbnail, lgZoom, lgVideo]} mode="lg-fade" download={false} >
                                            <img  src=${IMAGE_BASE_URL} ${imgObj.image_url} style={{ borderRadius: "10px 0 10px 10px", }} width="60" height="60" onclick="handleImage('${imgObj.image_url} /> 
                                        </LightGallery>
                                    </div>
                                    
                                    <div class="d-flex dats">
                                    <p>${imgObj.file_content}</p>
                                   <p>${convertLocaleMomentDate(Date.now())} ${statusIcon}</p>
                                    </div>                
                                </div>
                            </div>`
        } else if (new_res?.message_type == 'video') {
            var imgObj = JSON.parse(new_res.attachment_url)
            var varhtml = `<div class="d-flex my-4 align-content-end  ${alignClass}  mesgs">
                                <div class="d-flex imgs">
                                </div>
                                <div class="m_t">
                                    <div class="txts">
                                    <video width="320" height="240" controls>
                                        <source src="${IMAGE_BASE_URL} ${imgObj.image_url}" type="video/mp4" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60}>
                                        <source src="${IMAGE_BASE_URL} ${imgObj.image_url}" type="video/ogg" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60}>
                                        Your browser does not support the video tag.
                                    </video>
                                     <p>${imgObj.file_content}</p>
                                    </div>
                                    <div class="d-flex dats">
                                         <p>${convertLocaleMomentDate(Date.now())} ${statusIcon}</p>
                                    </div>
                                </div>
                            </div>`
        } else if (new_res?.message_type == 'pdf') {
            var imgObj = JSON.parse(new_res.attachment_url)
            var varhtml = `<div class="d-flex my-4 align-content-end ${alignClass}  mesgs">
                            <div class="d-flex imgs">
                            </div>
                            <div class="m_t">
                                <div class="txts">
                                    <a href="${imgObj}" target="_blank"> 
                                    <i class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i>
                                    </a>
                                </div>
                                <div class="d-flex dats">
                                    <p>${convertLocaleMomentDate(Date.now())} ${statusIcon}</p>
                                </div>
                            </div>
                        </div>`
        }

        if (sessionStorage.getItem('socket_created_at') === undefined && sessionStorage.getItem('socket_group_id') === undefined && sessionStorage.getItem('socket_message') === undefined) {
            console.log("varhtmlvarhtml varhtml", varhtml);
            $(".message_list").append(varhtml);
            sessionStorage.setItem('socket_created_at', new_res?.created_at);
            sessionStorage.setItem('socket_group_id', new_res?.group_id);
            sessionStorage.setItem('socket_message', new_res?.message);
            sessionStorage.setItem('socket_flag', 1);
        }
        else if (sessionStorage.getItem('socket_created_at') === new_res?.created_at && sessionStorage.getItem('socket_group_id') === new_res?.group_id && sessionStorage.getItem('socket_message') === new_res?.message) {
            return
        } else {
            $(".message_list").append(varhtml);
            sessionStorage.setItem('socket_created_at', new_res?.created_at);
            sessionStorage.setItem('socket_group_id', new_res?.group_id);
            sessionStorage.setItem('socket_message', new_res?.message);
            sessionStorage.setItem('socket_flag', 1);
        }

        scrollToBottomChat();
    })

    const productDetails = JSON.parse(sessionStorage.getItem('productDetails'))

    // const checkProductMsg = () => {
    //     if (productDetails) {
    //         // console.log("MessaageTransfer?.details--", productDetails);
    //         const attachment = {
    //             product_url: productDetails?.slug + '/' + productDetails?.id,
    //             image_url: productDetails?.image
    //         }
    //         const { carat, certificate, clarity, color, shape, seller_id } = productDetails;
    //         var message = `${shape} ${carat}ct ${color} ${clarity} ${certificate}`;
    //         var data = {
    //             product_id: productDetails?.id,
    //             slug: productDetails?.slug,
    //             other_user_id: seller_id,
    //             message: message,
    //             attachment_url: JSON.stringify(attachment),
    //             type: 'ENQUIRY'
    //         }
    //         socket.emit('send_message', encryptData(data));
    //         // socket.emit('total_message_count', {
    //         //     user_id: other_user_id,
    //         // });
    //         socket.emit('total_message_count', {});
    //         scrollToBottomChat();
    //         // dispatch({
    //         //     type: 'MESSAGE_TRANSFER_FAIL',
    //         //     payload: null
    //         // })
    //         sessionStorage.removeItem('productDetails')
    //     }
    // }
    useEffect(() => {
        if (productDetails) {
            const attachment = {
                product_url: productDetails?.slug + '/' + productDetails?.id,
                image_url: productDetails?.image
            }
            const { carat, certificate, clarity, color, shape, seller_id } = productDetails;
            var message = `${shape} ${carat}ct ${color} ${clarity} ${certificate}`;
            var data = {
                product_id: productDetails?.id,
                slug: productDetails?.slug,
                other_user_id: seller_id,
                message: message,
                attachment_url: JSON.stringify(attachment),
                type: 'ENQUIRY'
            }
            socket.emit('send_message', encryptData(data));
            socket.emit('total_message_count', {});
            scrollToBottomChat();
        }
    }, [productDetails])

    useEffect(() => {
        if (socket.connected) {
            window.onload = () => {
                setTimeout(() => {
                    sessionStorage.removeItem('productDetails');
                }, 2500);
            }
        }
    }, [socket.connected, socket]);

    // useEffect(() => {
    //     if (socket.connected) {
    //         window.onload = () => {
    //             sessionStorage.removeItem('productDetails')
    //         };
    //     }
    // }, [socket.connected])

    const handleButtonClick = (msg) => {

        setIsMsgRead(false);
        if (socket.connected === false) {
            socket.connected = true;
        }
        var data = {
            other_user_id: other_user_id,
            message: msg,
            type: 'TEXT'
        }
        socket.emit('send_message', encryptData(data));
        socket.emit('total_message_count', encryptData({
            user_id: other_user_id,
        }));
        socket.emit('total_message_count', {});
        scrollToBottomChat();
    };

    const handleImage = async () => {
        $('.s-i-image').css('display', 'block');
    };

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    return (
        <Fragment>
            {
                messageHistory ?
                    <Fragment>
                        <div className="col-md-9 pt-4">

                            <div className="msgdetail">
                                <h3>
                                    <Avatar name={other_user_name} src={other_profile_picture_full_thumb} color="#012F5B" size="45" value="86%" round />
                                    <span style={{ paddingLeft: '12px' }}>{other_user_id == '-1' ? 'Cevaheer' : other_user_name}</span>
                                </h3>
                                <div className="clearfix"></div>
                                <div className="message_list">
                                    {
                                        messageHistory?.slice(0).reverse() && messageHistory.slice(0).reverse().map((messageData, key) => {
                                            //  console.log("messageData---->", messageData);

                                            let attachment = messageData?.attachment_url
                                            let content = messageData?.file_content
                                            if (isJson(attachment)) {
                                                attachment = JSON.parse(attachment)
                                            }
                                            return (
                                                <Fragment key={messageData?.id}>
                                                    {
                                                        user_id === messageData.user_id ?
                                                            <div className={`d-flex my-4 align-content-end u-messgae mesgs ${messageData?.attachment_url != 'null' && 'NewChatLink'}`} >
                                                                <div>
                                                                    <div
                                                                        className="d-flex align-items-center txts"
                                                                        onClick={() => attachment?.product_url ? window.location.href = url + "/details/" + attachment?.product_url : {}}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        {
                                                                            attachment?.image_url &&
                                                                            <div className="s-i-image">
                                                                                <LightGallery
                                                                                    plugins={[lgThumbnail, lgZoom, lgVideo]}
                                                                                    mode="lg-fade"
                                                                                    download={false}>
                                                                                    <img
                                                                                        src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment}
                                                                                        alt="Product Image"
                                                                                        style={{ borderRadius: "10px 0 10px 10px" }}
                                                                                        width={60}
                                                                                        height={60}
                                                                                        onClick={() => { handleImage(messageData?.attachment_url, 'image') }} />
                                                                                </LightGallery>
                                                                            </div>
                                                                        }
                                                                        <div className='chatlinktext'>
                                                                            <p>{processText(messageData.message)}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex dats">
                                                                        <p>{convertLocaleMomentDate(messageData?.created_at)}  {(messageData?.is_read === 1) ? (
                                                                            <i className="fa-solid fa-check-double  text-right font-14 class-blue"></i>
                                                                        ) : messageData?.is_read === 0 && messageData?.is_delivered === 0 ? (
                                                                            <i className="fa fa-check text-right font-14" aria-hidden="true"></i>
                                                                        ) : messageData?.is_read === 0 && messageData?.is_delivered === 1 ? (
                                                                            <i className="fa-solid fa-check-double d-block text-right font-14"></i>
                                                                        ) : null}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className={`d-flex my-4 align-content-end ownCmnt mesgs ${messageData?.attachment_url && 'NewChatLink'}`}>
                                                                <div>
                                                                    <div className="d-flex  align-items-center txts">
                                                                        {attachment?.image_url && (
                                                                            <div className="s-i-image" onClick={() => attachment?.product_url ? window.location.href = url + "/details/" + attachment?.product_url : {}}>
                                                                                {messageData?.message_type == 'image' && (
                                                                                    <><LightGallery plugins={[lgThumbnail, lgZoom, lgVideo]} mode="lg-fade" download={false}>
                                                                                        <img src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment} alt="Product Image" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60} onClick={() => { handleImage(attachment?.image_url ? attachment?.image_url : attachment, "image"); }} />

                                                                                    </LightGallery><p>{processText(attachment?.file_content ? attachment?.file_content : attachment)}</p>
                                                                                    </>

                                                                                )}
                                                                                {messageData?.message_type == 'video' && (
                                                                                    <><video width="320" height="240" controls>
                                                                                        <source src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment} type="video/mp4" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60}></source>
                                                                                        <source src={attachment?.image_url ? attachment?.image_url : attachment} type="video/ogg" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60}></source>

                                                                                        Your browser does not support the video tag.

                                                                                    </video><p>{processText(attachment?.file_content ? attachment?.file_content : attachment)}</p></>

                                                                                )}
                                                                                {messageData?.message_type == 'pdf' && (
                                                                                    <a href={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment} target="_blank">
                                                                                        <i className="fa fa-file-pdf-o" style={{ fontSize: '48px', color: 'red' }}></i>
                                                                                    </a>

                                                                                )}

                                                                            </div>
                                                                        )}

                                                                        <div className="chatlinktext">
                                                                            {messageData?.message_type == 'text' || messageData?.message_type == 'TEXT' ?
                                                                                <>
                                                                                    <div className="d-flex s-i-image">
                                                                                        {
                                                                                            attachment?.image_url &&
                                                                                            <LightGallery plugins={[lgThumbnail, lgZoom, lgVideo]} mode="lg-fade" download={false}  >
                                                                                                <img src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : ""} alt="Product Image" style={{ borderRadius: "10px 0 10px 10px" }} width={60} height={60} onClick={() => { handleImage(messageData?.attachment_url, 'image') }} />
                                                                                            </LightGallery>
                                                                                        }
                                                                                        {attachment?.product_url ? <a href={url + "/details/" + attachment?.product_url}>{processText(messageData.message)}</a> : <p> {processText(messageData.message)}</p>}
                                                                                    </div>

                                                                                </>
                                                                                :
                                                                                <div>
                                                                                    <a
                                                                                        className="d-flex align-items-center txts"
                                                                                        // onClick={() => messageData?.product_id ? window.location.href = url + "/details/diamonds/" + messageData?.product_id : {}}
                                                                                        href={url + "/details/diamonds/" + messageData?.product_id}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        target='_blank'
                                                                                    >
                                                                                        {
                                                                                            attachment?.image_url &&
                                                                                            <div className="s-i-image">
                                                                                                <LightGallery
                                                                                                    plugins={[lgThumbnail, lgZoom, lgVideo]}
                                                                                                    mode="lg-fade"
                                                                                                    download={false}
                                                                                                >
                                                                                                    <img
                                                                                                        src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment}
                                                                                                        alt="Product Image"
                                                                                                        style={{ borderRadius: "10px 0 10px 10px" }}
                                                                                                        width={60} height={60}
                                                                                                        onClick={() => { handleImage(messageData?.attachment_url, 'image') }} />
                                                                                                </LightGallery>
                                                                                            </div>
                                                                                        }
                                                                                        <div className="chatlinktext">
                                                                                            <p>{processText(messageData?.message)}</p>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex dats">
                                                                        <p>{convertLocaleMomentDate(messageData?.created_at)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    other_user_id != '-1' &&
                                    <div className="row mt-5 pt-4 brdr-top msgFooter">
                                        <div className="col-md-12 pl-md-0">
                                            <div className='chat-suggestion'>
                                                {Array.isArray(shortmsg) && shortmsg.length > 0 ? (
                                                    shortmsg.map((msg, index) => (
                                                        <button className="btn cs-btn"
                                                            onClick={() => handleButtonClick(msg)}
                                                        >{msg}
                                                        </button>
                                                    ))
                                                ) : (
                                                    <p></p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-9 pl-md-0">
                                            <input type="text" placeholder={localStorage.getItem('lang') == 'tr' ? 'Mesaj yazın...' : 'Write a message...'} className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => handleSendMessageKeyPress(e)} />
                                        </div>
                                        <div className="col-md-3 pl-md-0 pr-md-0">
                                            <button className="btn-black" onClick={handleSendMessage}><FormattedMessage id="Send" defaultMessage="Send" /></button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                    :
                    <Welcome />
            }
        </Fragment>
    )
}

export default Chatbox
