import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAttributes } from "../../actions/productActions";
import { validateFields } from '../../validation';
import classnames from 'classnames';
import $ from 'jquery';
import { IMAGES } from '../../constants';

const ColorStone = ({ props, handleEditExcelForm }) => {
    const dispatch = useDispatch();
    const { attributes } = useSelector(state => state.attributes)

    const [addproduct, setAddProduct] = useState({
        stock_no: "",
        carat: "",
        certificate: "",
        certificate_no: "",
        measurement: "",
        pricect: "",
        shape: "",
        stone: "",
        comment: "",
        image_1: "",
        image_2: "",
        image_3: "",
        image_4: "",
        image_5: "",
        city: "",
        country: "",
        state: "",
        video: ""
    })

    const [validerror, setValidError] = useState({
        stock_noError: "",
        caratError: "",
        certificateError: "",
        certificate_no: "",
        measurementError: "",
        priceError: "",
        shapeError: ""
    });

    const handleInputChange = (event) => {
        setValidError({
            stock_noError: "",
            caratError: "",
            certificateError: "",
            measurementError: "",
            priceError: "",
            shapeError: ""
        })
        setAddProduct({ ...addproduct, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const stock_noError = validateFields.validateStock_Number(addproduct.stock_no);
        const shapeError = validateFields.validateShape(addproduct.shape);
        const certificateError = validateFields.validateCertificate(addproduct.certificate);
        const measurementError = validateFields.validateMeasurement(addproduct.measurement);
        const priceError = validateFields.validatePrice(addproduct.pricect);
        const caratError = validateFields.validateCarat(addproduct.carat);
        const stone_typeError = validateFields.validateStoneType(addproduct.stone);

        if ([stock_noError, caratError, certificateError, measurementError, priceError, shapeError, stone_typeError].every(e => e === false)) {
            setValidError({
                ...validerror, stock_noError, caratError, certificateError, measurementError, priceError, shapeError, stone_typeError
            })
            handleEditExcelForm(addproduct);
            $('#dismiss-modal').trigger('click');
        }
        else {
            setValidError({
                ...validerror, stock_noError, caratError, certificateError, measurementError, priceError, shapeError, stone_typeError
            })
        }
        return;
    }
    
    useEffect(() => {
        dispatch(getAttributes('color-stones'))
    }, [dispatch])

    useEffect(() => {
        if (props) {
            setAddProduct({
                stock_no: props && props.stock_no,
                carat: props && props.carat,
                certificate: props && props.certificate,
                certificate_no: props && props.certificate_no,
                measurement: props && props.measurement,
                pricect: props && props.pricect,
                shape: props && props.shape,
                stone: props && props.stone,
                comment: props && props.comment,
                image_1: props && props.image_1,
                image_2: props && props.image_2,
                image_3: props && props.image_3,
                image_4: props && props.image_4,
                image_5: props && props.image_5,
                city: props && props.city,
                country: props && props.country,
                state: props && props.state,
                video: props && props.video
            })
        }
    }, [props])


    const delete_image = (key) => {
        if (key === 1) {
            setAddProduct({ ...addproduct, image_1: false })
        }
        else if (key === 2) {
            setAddProduct({ ...addproduct, image_2: false })
        }
        else if (key === 3) {
            setAddProduct({ ...addproduct, image_3: false })
        }
        else if (key === 4) {
            setAddProduct({ ...addproduct, image_4: false })
        }
        else if (key === 5) {
            setAddProduct({ ...addproduct, image_5: false })
        }
    }

    return (
        <Fragment>
            <div className="modal fade editproduct_form" id="editproductcolorstone" tabindex="-1" role="dialog" aria-labelledby="editproductTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <button id="dismiss-modal" type="button" className="close m-0" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 className="modal-title" id="editproductTitle">Color Stones</h5>
                                <button type="submit" className="btn btn-black">Save Changes</button>
                            </div>
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div className="titles nbrd mt-4 mb-3 pb-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className="m-0">Edit Product</h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stock No.</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.stock_noError === false }, { 'is-invalid': validerror.stock_noError })} type="text" placeholder="Stock No." name="stock_no" value={addproduct.stock_no} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.stock_noError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Shape</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.shapeError === false }, { 'is-invalid': validerror.shapeError })} value={addproduct.shape} name="shape" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.shape && attributes.shape.map((shape) => {
                                                                return (
                                                                    <option value={shape.name}>{shape.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.shapeError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stone</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.stone_typeError === false }, { 'is-invalid': validerror.stone_typeError })} value={addproduct.stone} name="stone" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.stone_type && attributes.stone_type.map((stone_type) => {
                                                                return (
                                                                    <option value={stone_type.name}>{stone_type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.stone_typeError}</p>
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Carat</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.caratError === false }, { 'is-invalid': validerror.caratError })} name="carat" value={addproduct.carat} onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.carat && attributes.carat.map((carat) => {
                                                                return (
                                                                    <option value={carat.name}>{carat.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.caratError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Measurement</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.measurementError === false }, { 'is-invalid': validerror.measurementError })} type="text" placeholder="measurement" name="measurement" value={addproduct.measurement} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.measurementError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Price/ct</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.priceError === false }, { 'is-invalid': validerror.priceError })} type="text" placeholder="price/ct" name="pricect" value={addproduct.pricect} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.priceError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Certificate</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.certificateError === false }, { 'is-invalid': validerror.certificateError })} value={addproduct.certificate} name="certificate" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.certificate && attributes.certificate.map((certificate) => {
                                                                return (
                                                                    <option value={certificate.name}>{certificate.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.certificateError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Certificate No.</label>
                                                    <input className="form-control" type="text" placeholder="certificate_no" name="certificate_no" value={addproduct.certificate_no} onChange={handleInputChange} />
                                                </div>
                                            </div>

                                        </div>


                                        <hr className="w-100" />

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Comment (optional)</label>
                                                    <textarea onChange={handleInputChange} placeholder="Stone looks milky" rows="5" className="form-control" value={addproduct.comment} name="comment"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <h3 className="forms toBrdr">
                                            Upload Product Photos or Videos
                                            <p>JPEG, PNG supported, max 10mb per image</p>
                                        </h3>

                                        <div className="row imgEdits mb-4">
                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_1} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(1)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_2} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(2)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_3} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(3)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_4} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(4)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_5} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(5)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row actions my-5">
                                            <div className="col-md-6">
                                                <button type="submit" className="btns btn-black  mr-2 mb-2">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default ColorStone
