import React, { Fragment, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useSelector, useDispatch } from 'react-redux';
import MetaData from './layout/MetaData';
import Loader from './layout/Loader'; 
import Header from './layout/Header';
import Footer from './layout/Footer';
import HomeSection from './HomePage/HomeSection';
import NumberOfMember from './HomePage/NumberOfMember';
import Testimonial from './HomePage/Testimonial';
import Pricing from './HomePage/Pricing';
import WhyCevaheer from './HomePage/WhyCevaheer';
import NewHome from './newHome/NewHome';

import { getHomePage } from '../actions/HomePageActions';
import mixpanel from 'mixpanel-browser';

const Home = () => {

  const alert = useAlert()
  const dispatch = useDispatch()

  useEffect(() =>{
    mixpanel.track('Home')
   }, [])

  useEffect(async () => {
    await dispatch(getHomePage())
  }, [dispatch])

  const homeState = useSelector(state => state.home)

  if (homeState?.error) {
    alert.error(homeState?.error);
    return
  } 

  return (
    <Fragment>
      {homeState?.loading && <Loader />}
      <Fragment>
        <MetaData title={'CEVAHEER'} />
        <NewHome/>
        {/* 
         <Header />
        <HomeSection />
        <WhyCevaheer />
        <Pricing />
        <Testimonial />
        <NumberOfMember />
        <Footer /> */}
      </Fragment>
    </Fragment>
  )
}

export default Home