import {
    GET_HOMEPAGE_REQUEST, GET_HOMEPAGE_SUCCESS, GET_HOMEPAGE_FAIL,
    TERM_CONDITION_REQUEST,
    TERM_CONDITION_SUCCESS,
    TERM_CONDITION_FAIL,
    POLICY_REQUEST,
    POLICY_SUCCESS,
    POLICY_FAIL,
    ELECTRONIC_MESSAGE_REQUEST,
    ELECTRONIC_MESSAGE_SUCCESS,
    ELECTRONIC_MESSAGE_FAIL
} from "../constants/HomePageConstant";

export const homePageReducer = (state = { Home: {} }, action) => {

    switch (action.type) {
        case GET_HOMEPAGE_REQUEST:
            return {
                loading: true,
                Home: {}
            }
        case GET_HOMEPAGE_SUCCESS:
            return {
                loading: false,
                Home: action.payload
            }
        case GET_HOMEPAGE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}


export const termConditionReducer = (state = { Term: null }, action) => {

    switch (action.type) {
        case TERM_CONDITION_REQUEST:
            return {
                loading: true,
                Term: {}
            }
        case TERM_CONDITION_SUCCESS:
            return {
                loading: false,
                Term: action.payload
            }
        case TERM_CONDITION_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}


export const policyReducer = (state = { Policy: null }, action) => {

    switch (action.type) {
        case POLICY_REQUEST:
            return {
                loading: true,
                Policy: {}
            }
        case POLICY_SUCCESS:
            return {
                loading: false,
                Policy: action.payload
            }
        case POLICY_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}

export const electronicMsgReducer = (state = { ElectronicMsg: null }, action) => {
    switch (action.type) {
        case ELECTRONIC_MESSAGE_REQUEST:
            return {
                loading: true,
                ElectronicMsg: {}
            }
        case ELECTRONIC_MESSAGE_SUCCESS:
            return {
                loading: false,
                ElectronicMsg: action.payload
            }
        case ELECTRONIC_MESSAGE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}