import React from 'react';
import { useSelector } from 'react-redux';
import { IMAGES } from '../../constants';
import { ImageUrl } from '../../helper';

const WhyCevaheer = () => {


    const { Home } = useSelector(state => state.home)

    return (
        <section className='HWEWhyCevaheer' id='WHYCevaheers'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-7'>
                    <p className='hsubHeading'>{Home && Home?.homePage && Home?.homePage?.why_section_one_heading}</p>
                    <h2 className='NewTitle whiteText'>{Home && Home?.homePage && Home?.homePage?.why_sect_title}</h2>
                    <p className='HWEsubText'>"{Home && Home?.homePage && Home?.homePage?.why_section_one_desc}"</p>
                    <ul>
                        <li>
                            <img src={IMAGES.newICOn1} alt="banner img" />
                            <p> {Home && Home?.homePage && Home?.homePage?.w_sec_one_col_one}</p>
                        </li>
                        <li>
                            <img src={IMAGES.newICOn2} alt="banner img" />
                            <p>{Home && Home?.homePage && Home?.homePage?.w_sec_one_col_two}</p>
                        </li>
                        <li>
                            <img src={IMAGES.newICOn3} alt="banner img" />
                            <p>{Home && Home?.homePage && Home?.homePage?.w_sec_one_col_three}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
   
    )
}
export default WhyCevaheer