import * as yup from "yup";
import { ValidationMessage } from "./CommonValidation";


/**
 * stock_numberError: "",
        shapeError: "",
        caratError: "",
        colorError: "",
        clarityError: "",
        certificateError: "",
        milkyError: "",
        availabilityStatusError: "",
        priceError: "",
        depth_perError: "",
        mm_l_Error: "",
        mm_h_Error: "",
        mm_w_Error: "",
        table_perError: "",
 */
const ColorStoneValidation = yup.object().shape({
    product_title: yup.string()
        .notRequired()
        .trim(),
    stone_type: yup.string()
        .required(ValidationMessage.Field("stone type"))
        .trim(),
    shape: yup.string()
        .required(ValidationMessage.Field("shape"))
        .trim(),
    carat: yup.string()
        .required(ValidationMessage.Field("carat"))
        .matches(/^\d+(\.\d+)?$/, "Only numeric digits and  period is allowed.")
        .trim(),
    mm_h: yup.string()
        .required("depth is required")
        .matches(/^\d+(\.\d+)?$/, "Only numeric digits and  period is allowed.")
        .trim(),
    mm_l: yup.string()
        .required("length is required")
        .matches(/^\d+(\.\d+)?$/, "Only numeric digits and  period is allowed.")
        .trim(),
    mm_w: yup.string()
        .required("width is required")
        .matches(/^\d+(\.\d+)?$/, "Only numeric digits and  period is allowed.")
        .trim(),
    certificate: yup.string()
        .required(ValidationMessage.Field("certificate"))
        .trim(),
    certificate_no: yup.string()
        // .required(ValidationMessage.Field("certificate number"))
        .trim(),
    price: yup.string()
        .required(ValidationMessage.Field("price"))
        .matches(/^\d+(\.\d+)?$/, "Only numeric digits and  period is allowed.")
        .trim(),
    stock_number: yup.string()
        .required(ValidationMessage.Field("stock number"))
        .trim(),
    images: yup.mixed()
        // .test("required", ValidationMessage?.ANY_CHOOSE + " Image ", (file) => {
        //     if (file.length == 0) { return false };
        //     return true;
        // })
        .notRequired()
        .test("fileType", ValidationMessage?.UNSUPPORTED + " Please choose jpg, jpeg and png", (file) => {
            if (file?.length > 0 && file[0]?.type) {
                var file_type = file?.length && ["image/jpeg", "image/png", "image/jpg"].includes(file[0]?.type)
                if (file_type) {
                    return file_type;
                }
                else {
                    return file_type;
                }
            }
            else {
                return true;
            }
        }),
    video: yup.string()
        .notRequired()
    // .required(ValidationMessage.Field("name"))
    // .trim(),
    ,
    comment: yup.string()
        .notRequired()
        // .required(ValidationMessage.Field("comment"))
        .trim(),
});

export default ColorStoneValidation;