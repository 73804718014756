import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearError, clearMessage, getAttributes, GetModelAttribute, updateWatches } from "../../actions/productActions";
import { getLastURL, getSecondLastURL, ImageUrl, Year } from '../../helper';
import { validateFields } from '../../validation';
import classnames from 'classnames';
import $ from 'jquery';
import { useLocation } from 'react-router';
import { IMAGE_BASE_URL, url } from '../../config';
import Loader from '../layout/Loader';
import { IMAGES } from '../../constants';
import { FormattedMessage } from 'react-intl';
import { ExtentionSupport } from '../../utils/json';
import { deleteImage } from '../../utils/CommonApi';
import heic2any from 'heic2any';

const Watch = ({ props, handleEditExcelForm }) => {
    const dispatch = useDispatch();
    const video1 = {};
    const [images, setImages] = useState([]);
    const [imageToShow, setImageToShow] = useState([]);
    const [video, setVideo] = useState(null);
    const [deleteImgLoader, setDeleteImgLoader] = useState(false);
    const fileInputRef = useRef(null);

    const { attributes } = useSelector(state => state.attributes);
    const product = useSelector(state => state.productDetails);
    const { modelattribute } = useSelector(state => state.ModelAttribute);
    const { updateproduct, data, error } = useSelector(state => state.updateWatchReducer);
    const [loading, setLoading] = useState(true);
    const [imgLoading, setImgLoading] = useState(false);

    const location = useLocation();
    const id = getLastURL(location);
    const category_slug = getSecondLastURL(location);

    const [addproduct, setAddProduct] = useState({
        category_slug: category_slug || "",
        id: id || "",
        stock_number: "",
        brand: "",
        case_material: "",
        case_size_mm: "",
        w_condition: "",
        dial_numerals: "",
        dial_type: "",
        functions: "",
        gender: "",
        model: "",
        movement: "",
        papers_box: "",
        price: "",
        reference: "",
        strap_color: "",
        year: "",
        bezel_material: "",
        comment: "",
        strap_material: "",
        showcase_image: "",
        // image_1: "",
        // image_2: "",
        // image_3: "",
        // image_4: "",
        // image_5: "",
        // description: ""
    })

    useEffect(() => {
        setAddProduct(prevState => ({
            ...prevState,
            category_slug: category_slug || prevState?.category_slug,
            id: id || prevState?.id
        }));
    }, [category_slug, id]);

    const [validerror, setValidError] = useState({
        stock_numberError: "",
        brandError: "",
        modelError: "",
        strap_colorError: "",
        case_sizeError: "",
        yearError: "",
        papers_boxError: "",
        conditionError: "",
        genderError: "",
        movementError: "",
        functionsError: "",
        dial_numeralsError: "",
        dial_typeError: "",
        case_materialError: "",
        bezel_materialError: "",
        priceError: "",
        strap_materialError: ""
    });

    useEffect(() => {
        if (updateproduct) {
            // $("#popup-click-sucess").trigger('click');
            // alert.success(updateproduct);
            //console.log("updateproduct---",updateproduct);
            dispatch(clearMessage())
            window.location = url + '/myproducts/watches';
        }

        if (error) {
            alert.error(error)
            dispatch(clearError())
        }
    }, [updateproduct, dispatch, error, alert, data]);

    const handleInputChange = (event) => {
        setValidError({
            stock_numberError: "",
            brandError: "",
            modelError: "",
            strap_colorError: "",
            case_sizeError: "",
            yearError: "",
            papers_boxError: "",
            conditionError: "",
            genderError: "",
            movementError: "",
            functionsError: "",
            dial_numeralsError: "",
            dial_typeError: "",
            case_materialError: "",
            bezel_materialError: "",
            priceError: "",
            strap_materialError: ""
        })
        setAddProduct({ ...addproduct, [event.target.name]: event.target.value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const stock_numberError = validateFields.validateStock_Number(addproduct?.stock_number);
        const brandError = validateFields.validateBrand(addproduct?.brand);
        const modelError = validateFields.validateModel(addproduct?.model);
        const strap_colorError = validateFields.validateStrapColor(addproduct?.strap_color);
        const case_sizeError = validateFields.validateCaseSize(addproduct?.case_size_mm);
        const yearError = validateFields.validateYear(addproduct?.year);
        const papers_boxError = validateFields.validatePapers(addproduct?.papers_box);
        const conditionError = validateFields.validateCondition(addproduct?.w_condition);
        const genderError = validateFields.validateGender(addproduct?.gender);
        const movementError = validateFields.validateMovement(addproduct?.movement);
        const functionsError = validateFields.validateFunction(addproduct?.functions);
        const dial_numeralsError = validateFields.validateDialNumeral(addproduct?.dial_numerals);
        const dial_typeError = validateFields.validateDialType(addproduct?.dial_type);
        const case_materialError = validateFields.validateCaseMaterial(addproduct?.case_material);
        const bezel_materialError = validateFields.validateBezelMaterial(addproduct?.bezel_material);
        const priceError = validateFields.validatePriceForWatch(addproduct?.price);
        const strap_materialError = validateFields.validateStrapMaterial(addproduct?.strap_material);

        if ([stock_numberError, brandError, modelError, strap_colorError, case_sizeError, yearError, papers_boxError, conditionError, genderError, movementError, functionsError, dial_numeralsError, dial_typeError, case_materialError, bezel_materialError, priceError, strap_materialError].every(e => e === false)) {
            setValidError({
                ...validerror, stock_numberError, brandError, modelError, strap_colorError, case_sizeError, yearError, papers_boxError, conditionError, genderError, movementError, functionsError, dial_numeralsError, dial_typeError, case_materialError, bezel_materialError, priceError, strap_materialError
            })
            await dispatch(updateWatches(addproduct))

            // handleEditExcelForm(addproduct);
            $('#dismiss-modal').trigger('click');
        }
        else {
            setValidError({
                ...validerror, stock_numberError, brandError, modelError, strap_colorError, case_sizeError, yearError, papers_boxError, conditionError, genderError, movementError, functionsError, dial_numeralsError, dial_typeError, case_materialError, bezel_materialError, priceError, strap_materialError
            })
        }
        return;
    }

    useEffect(() => {
        dispatch(getAttributes('watches'))
        dispatch(GetModelAttribute('watches', [252]))
    }, [dispatch])

    useEffect(() => {
        if (product?.details?.productDetails) {
            setLoading(false)
            setAddProduct((prevState) => ({
                ...prevState,
                stock_number: product?.details?.productDetails && product?.details?.productDetails?.stock_number,
                brand: product?.details?.productDetails && product?.details?.productDetails?.brand,
                case_material: product?.details?.productDetails && product?.details?.productDetails?.case_material,
                case_size_mm: product?.details?.productDetails && product?.details?.productDetails?.case_size_mm,
                w_condition: product?.details?.productDetails && product?.details?.productDetails?.w_condition,
                dial_numerals: product?.details?.productDetails && product?.details?.productDetails?.dial_numerals,
                dial_type: product?.details?.productDetails && product?.details?.productDetails?.dial_type,
                functions: product?.details?.productDetails && product?.details?.productDetails?.functions,
                gender: product?.details?.productDetails && product?.details?.productDetails?.gender,
                model: product?.details?.productDetails && product?.details?.productDetails?.model,
                movement: product?.details?.productDetails && product?.details?.productDetails?.movement,
                papers_box: product?.details?.productDetails && product?.details?.productDetails?.papers_box,
                price: product?.details?.productDetails && product?.details?.productDetails?.price,
                reference: product?.details?.productDetails && product?.details?.productDetails?.reference,
                strap_color: product?.details?.productDetails && product?.details?.productDetails?.strap_color,
                year: product?.details?.productDetails && product?.details?.productDetails?.year,
                bezel_material: product?.details?.productDetails && product?.details?.productDetails?.a_bezel_material?.attribute_id,
                comment: product?.details?.productDetails && product?.details?.productDetails?.supplier_comment,
                strap_material: product?.details?.productDetails && product?.details?.productDetails?.strap_material,
                showcase_image: product?.details?.productDetails && product?.details?.productDetails?.showcase_image,
                // image_1: props && props.image_1,
                // image_2: props && props.image_2,
                // image_3: props && props.image_3,
                // image_4: props && props.image_4,
                // image_5: props && props.image_5,
                comment: product?.details?.productDetails && product?.details?.productDetails?.comment

            })
            )
        }
        if (product?.details?.singleVideo) {
            setVideo({ url: ImageUrl(product?.details?.singleVideo?.video), id: product?.details?.singleVideo?.id })
        }

        const imgs = product?.details?.productImages?.map(i => {
            return {
                url: ImageUrl(i?.file),
                id: i?.id
            }
        })
        setImageToShow(imgs)
    }, [product?.details?.productDetails])

    const check_duplicate = (name) => {
        let image = true;
        if (images && images.length > 0) {
            for (let e = 0; e < images.length; e++) {
                if (images[e].name == name) {
                    image = false;
                    break;
                }
            }
        }
        return image;
    }

    const convertArrayToFileList = (filesArray) => {
        const dataTransfer = new DataTransfer();
        filesArray?.forEach(fileObj => dataTransfer?.items?.add(fileObj?.file));
        return dataTransfer?.files;
    };

    useEffect(() => {
        if (images?.length > 0) {
            setAddProduct({ ...addproduct, images: convertArrayToFileList(images) });
        } else {
            setAddProduct({ ...addproduct, images: null });
        }
    }, [images])

    const image_select = async (e) => {
        setImgLoading(true)
        let image = e?.target?.files;

        const maxSize = 10 * 1024 * 1024;
        let tempImgArr = []
        let tempImgArr2Show = []
        let remaining_space = 5 - images?.length;
        let max_len = image?.length > remaining_space ? remaining_space : image?.length;

        for (let i = 0; i < max_len; i++) {
            let file = image[i];
            const fileName = file.name;
            const fileType = fileName?.split('.').pop();

            if (!ExtentionSupport.includes(fileType)) {
                alert.error(fileName + " has an unsupported file format.");
                continue;
            }

            if (file.size > maxSize) {
                alert.error(fileName + " exceeds the 10MB size limit");
                continue;
            }

            if (check_duplicate(fileName)) {
                if (fileType === "heic" || fileType === "heif") {

                    try {
                        console.log("hi,.heic 11");
                        
                        const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
                        console.log("hi,.heic 22");
                        file = new File([convertedBlob], fileName.replace(".heic", ".jpg"), { type: "image/jpeg" });
                    } catch (error) {
                        alert.error("Failed to convert HEIC file: " + fileName);
                        continue;
                    }

                }
                tempImgArr2Show?.push({
                    "name": fileName,
                    "url": URL.createObjectURL(file),
                    "size": file.size,
                    "id": ""
                })

                tempImgArr.push({
                    "name": fileName,
                    "url": URL.createObjectURL(file),
                    "file": file,
                    "size": file.size
                })
            } else {
                alert.error(fileName + " is already added to the list");
            }
        }
        setImageToShow((prevImg) => [...prevImg, ...tempImgArr2Show])
        setImages((prevImg) => [...prevImg, ...tempImgArr]);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setImgLoading(false)
    }

    const video_select = (e) => {
        const ext = e?.target?.files[0]?.name.split('.').pop();
        if (ext == 'mp4') {
            let video = e?.target?.files;
            let videoUp = e?.target?.files[0];

            setAddProduct({ ...addproduct, video: videoUp })
            if (videoUp) {
                video1.name = video[0].name
                video1.url = URL.createObjectURL(video[0])
                video1.file = video[0]
                video1.size = video[0].size
                setVideo({ url: video1.url, id: "" });
                var videor = document.getElementById('video');
                var source = document.createElement('source');
                source.setAttribute('src', video1.url);
                source.setAttribute('type', 'video/mp4');
                videor?.load();
            }
        } else {
            alert.error("Please upload supported file format");
        }
    }
    const deleteImgOrVideo = async (id, type) => {
        const payload = {
            id: id,
            type: type
        }
        setDeleteImgLoader(true)
        await deleteImage(payload)
        setDeleteImgLoader(false)
    }

    const delete_image = (index, id) => {
        if (id) {
            deleteImgOrVideo(id, "img")
        }
        setImages(images.filter((x, i) => i !== index));
        setImageToShow(imageToShow?.filter((x, i) => i !== index));

        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    const delete_video = (id) => {
        if (id) {
            deleteImgOrVideo(id, "video")
        }
        setVideo(null);
        $('#video_upload').val('');
        // setAddProduct({ ...addproduct, video: null });
        // setValue("video", null)
    }

    return (
        <Fragment>
            {(loading || imgLoading) ? <Loader /> : ""}
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Stock No.<span className="strike_red">*</span></label>
                                    <input
                                        className={classnames("form-control", { 'is-valid': validerror.stock_numberError === false }, { 'is-invalid': validerror.stock_numberError })}
                                        type="text"
                                        placeholder="Stock No."
                                        name="stock_number"
                                        value={addproduct?.stock_number}
                                        onChange={handleInputChange} />
                                    <p className="invalid-feedback">{validerror.stock_numberError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Brand<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.brandError === false }, { 'is-invalid': validerror.brandError })}
                                        value={addproduct?.brand}
                                        name="brand"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.brand?.map((brand) => {
                                                return (
                                                    <option value={brand.id} selected={brand?.id == addproduct?.brand ? true : false}  >{brand.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.brandError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Model<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.modelError === false }, { 'is-invalid': validerror.modelError })}
                                        name="model"
                                        alue={addproduct?.model}
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            modelattribute?.model.map((model) => {
                                                // console.log(model?.id ,"==", addproduct?.model , "===")
                                                return (
                                                    <option value={model.id} selected={model?.id == addproduct?.model ? true : false} >{model.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.modelError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Year of production<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.yearError === false }, { 'is-invalid': validerror.yearError })}
                                        value={addproduct?.year}
                                        name="year"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            Year() && Year().map((year) => {
                                                return (
                                                    <option value={year}>{year}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.yearError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Case material<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.case_materialError === false }, { 'is-invalid': validerror.case_materialError })}
                                        value={addproduct?.case_material}
                                        onChange={handleInputChange}
                                        name="case_material">
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.case_material?.map((case_material) => {
                                                return (
                                                    <option value={case_material.id}>{case_material.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.case_materialError}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Case Size<span className="strike_red">*</span></label>
                                    <input
                                        onChange={handleInputChange}
                                        className={classnames("form-control", { 'is-valid': validerror.case_sizeError === false }, { 'is-invalid': validerror.case_sizeError })}
                                        type="text"
                                        placeholder="case size"
                                        value={addproduct?.case_size_mm}
                                        name="case_size_mm" />
                                    <p className="invalid-feedback">{validerror.case_sizeError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Reference No</label>
                                    <input
                                        onChange={handleInputChange}
                                        name="reference"
                                        className="form-control"
                                        type="text"
                                        placeholder="Reference No."
                                        value={addproduct?.reference} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Movement<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.movementError === false }, { 'is-invalid': validerror.movementError })}
                                        value={addproduct?.movement}
                                        onChange={handleInputChange}
                                        name="movement">
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.movement?.map((movement) => {
                                                return (
                                                    <option value={movement?.id}>{movement.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.movementError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Condition<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.conditionError === false }, { 'is-invalid': validerror.conditionError })}
                                        value={addproduct?.w_condition}
                                        name="w_condition"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.condition?.map((condition) => {
                                                return (
                                                    <option value={condition.id}>{condition.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.conditionError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Gender<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.genderError === false }, { 'is-invalid': validerror.genderError })}
                                        value={addproduct?.gender}
                                        name="gender"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.gender?.map((gender) => {
                                                return (
                                                    <option value={gender.id}>{gender.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.genderError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Functions<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.functionsError === false }, { 'is-invalid': validerror.functionsError })}
                                        value={addproduct?.functions}
                                        name="functions"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.functions?.map((functions) => {
                                                return (
                                                    <option value={functions.id}>{functions.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.functionsError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Papers & Box<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.papers_boxError === false }, { 'is-invalid': validerror.papers_boxError })}
                                        value={addproduct?.papers_box}
                                        name="papers_box"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.papers_box?.map((papers_box) => {
                                                return (
                                                    <option value={papers_box.id}>{papers_box.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.papers_boxError}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Dial Numerals<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.dial_numeralsError === false }, { 'is-invalid': validerror.dial_numeralsError })}
                                        value={addproduct?.dial_numerals}
                                        name="dial_numerals"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.dial_numerals?.map((dial_numerals) => {
                                                return (
                                                    <option value={dial_numerals.id}>{dial_numerals.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.dial_numeralsError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Dial Type<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.dial_typeError === false }, { 'is-invalid': validerror.dial_typeError })}
                                        value={addproduct?.dial_type}
                                        name="dial_type"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.dial_type?.map((dial_type) => {
                                                return (
                                                    <option value={dial_type.id}>{dial_type.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.dial_typeError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Strap material<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.strap_materialError === false }, { 'is-invalid': validerror.strap_materialError })}
                                        value={addproduct?.strap_material}
                                        name="strap_material"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.strap_material?.map((strap_material) => {
                                                return (
                                                    <option value={strap_material.id}>{strap_material.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.strap_materialError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Strap Color<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.strap_colorError === false }, { 'is-invalid': validerror.strap_colorError })}
                                        value={addproduct?.strap_color}
                                        name="strap_color"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.strap_color?.map((strap_color) => {
                                                return (
                                                    <option value={strap_color.id}>{strap_color.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.strap_colorError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Bezel material<span className="strike_red">*</span></label>
                                    <select
                                        className={classnames("form-control", { 'is-valid': validerror.bezel_materialError === false }, { 'is-invalid': validerror.bezel_materialError })}
                                        value={addproduct?.bezel_material}
                                        name="bezel_material"
                                        onChange={handleInputChange}>
                                        <option value="">{`Select`}</option>
                                        {
                                            attributes?.bezel_material?.map((bezel_material) => {
                                                return (
                                                    <option value={bezel_material.id}>{bezel_material.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <p className="invalid-feedback">{validerror.bezel_materialError}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Price<span className="strike_red">*</span></label>
                                    <input
                                        className={classnames("form-control", { 'is-valid': validerror.priceError === false }, { 'is-invalid': validerror.priceError })}
                                        type="text"
                                        name="price"
                                        placeholder="Price"
                                        value={addproduct?.price}
                                        onChange={handleInputChange} />
                                    <p className="invalid-feedback">{validerror.priceError}</p>
                                </div>
                            </div>

                            {/* <hr className="w-100" /> */}
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label htmlFor="">Comment (optional)</label>
                                    <textarea
                                        onChange={handleInputChange}
                                        maxLength={300}
                                        placeholder="Stone looks milky"
                                        rows="5"
                                        className="form-control"
                                        value={addproduct?.comment}
                                        name="comment"></textarea>
                                </div>
                            </div>
                        </div>
                        <h3 className="forms toBrdr">
                            <FormattedMessage
                                id="addsingleproduct.uploadimage"
                                defaultMessage="Upload Product Photos"
                            />
                            <p><FormattedMessage
                                id="addsingleproduct.supported-image"
                                defaultMessage="JPEG, PNG supported, max 10mb per image"
                            /></p>
                        </h3>
                        <div className='uploaded-product-grid'>
                            <div className='row'>
                                {
                                    imageToShow && imageToShow?.map((img, key) => {
                                        return (
                                            <div className='col-sm-6 col-lg-4 col-xl-3 mb-4'>
                                                <div className="imgEdits">
                                                    <div className="imgBlock">
                                                        <img className="w-100" src={img?.url} alt="" />
                                                        <div className="hover">
                                                            <img onClick={(e) => delete_image(key, img?.id)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                        </div>
                                                    </div>
                                                    {
                                                        img?.size ?
                                                            <div className="col-md-8 pro-ttl-ct">
                                                                <h4>{img?.name}</h4>
                                                                <p className="mb-10"><FormattedMessage
                                                                    id="addsingleproduct.ImageUploaded"
                                                                    defaultMessage="Image Uploaded"
                                                                /> ({img?.size / 1024}kb)</p>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            imageToShow?.length < 5 ?
                                <div className="row imgEdits mb-4">
                                    <div className="col-md-4">
                                        <div className="moreUpload">
                                            <span><img src={IMAGES.DOWNLOAD_SVG} alt="" /></span>
                                            <FormattedMessage
                                                id="addsingleproduct.drop-photo"
                                                defaultMessage="Drop Photos"
                                            />
                                            <input
                                                type="file"
                                                name="Image"
                                                id="image"
                                                multiple
                                                accept='.png,.jpg,.jpeg,.heic,.heif'
                                                onChange={image_select}
                                                ref={fileInputRef}
                                                disabled={imgLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }



                        {/* video Code start */}
                        <h3 className="forms toBrdr">
                            <FormattedMessage
                                id="addsingleproduct.uploadvideo"
                                defaultMessage="Upload Product Video"
                            />
                            <p><FormattedMessage
                                id="addsingleproduct.Mp4supported"
                                defaultMessage="Mp4 supported"
                            /></p>
                        </h3>
                        {
                            video ?
                                <div className="col-md-4 imgEdits">
                                    <div className="row imgBlock videoEdits mb-4">
                                        <div className="close-vid hover">
                                            <img onClick={() => delete_video(video?.id)} src={IMAGES.CLOSE_WHITE} alt="" />
                                        </div>
                                        <video id="video" style={{ 'max-width': '100%' }} >
                                            <source src={video?.url} type="video/mp4" />
                                            <FormattedMessage
                                                id="addsingleproduct.YourBrowserNotSupport"
                                                defaultMessage="Your browser does not support the video tag."
                                            />
                                        </video>
                                    </div>
                                </div>
                                : <div className="row imgEdits mb-4 videoUploadDiv">
                                    <div className="col-md-4">
                                        <div className="moreUpload">
                                            <span><img src={IMAGES.DOWNLOAD_SVG} alt="" /></span>
                                            <FormattedMessage
                                                id="addsingleproduct.drop-videos"
                                                defaultMessage="Drop Video"
                                            />
                                            <input type="file" name="video_upload" id="video_upload" accept='.mp4' onChange={video_select} />
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className="row actions my-5">
                            <div className="col-md-6">

                                {/* <button type="submit" className="btn btn-black">Save Changes</button> */}
                                <button type="submit" className="btns btn-black  mr-2 mb-2">Save Changes</button>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </Fragment >
    )
}

export default Watch
