import React, { useState } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { currency, url } from '../../config';
import { savePercentage, deviceDisplay } from '../../helper';
import { setColor } from '../../helper';
import { JEWELER_SMALL, JEWELER_SMALL_SEARCHES, FREEMIUM_SEARCHES, JEWELER_SMALL_TEXT, UNLIMITED_SEARCH } from '../../constants/index';
import { FormattedMessage } from 'react-intl';
import { IMAGES } from '../../constants'

const Jeweler = () => {

    const [plan_period, setPlanPeriod] = useState();

    const { Home } = useSelector(state => state.home)
    const { user, loading } = useSelector(state => state.user);
    const handlePlanPeriod = (event) => {
        setPlanPeriod(event.target.checked);
    }

    const stringReplace = (text) => {
        let replace = text.replace(/(<([^>]+)>)/ig, '');
        return replace;
    }


    return (
        <div className="tab-pane fade show active" id="jeweler" role="tabpanel" aria-labelledby="home-tab">
            <div className='row'>
                <div className='col-lg-11 mx-auto'>
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div className="cus_switch mb-3 d-flex align-item-center justify-content-center">
                                <p className="font-18 fr black-3B pr-3"><FormattedMessage id="Monthly" defaultMessage="Monthly" /></p>
                                <label className="switch">
                                    <input type="checkbox" onChange={handlePlanPeriod} />
                                    <span className="slider round"></span>
                                </label>
                                <p className="font-18 fr pl-3" style={{ color: "#164EA2" }}><b
                                    className="black-3B"><FormattedMessage id="Annual" defaultMessage="Annual" /></b> <FormattedMessage
                                        id="billing.save-percentage"
                                        defaultMessage="Save up to 16.19%"
                                    /></p>
                            </div>
                        </div>
                        {
                            Home && Home?.JewelerPlans && Home?.JewelerPlans.map((JP, key) => {
                                const secondWord = JP?.name?.split(" ")[1];
                                return (
                                    <div key={key} className="col-lg-4 col-md-4 mb-4">
                                        <div className="package-card-wrap">
                                            <div className='starImg'>
                                                <img src={IMAGES[`star_${JP?.user_type == 0 ? 2 : JP?.id == JEWELER_SMALL_TEXT ? 3 : 5}`]} alt="banner img" />
                                            </div>
                                            <div className="sub_plan_box">
                                                <h3 class={setColor(key)}>{JP?.name}</h3>
                                                {secondWord ? <span className="pakTag">{secondWord}</span> : null}
                                                {
                                                    JP?.user_type == 0
                                                        ? <div className="device_d">
                                                            {/* <p>{FREEMIUM_SEARCHES} <FormattedMessage id="Searches_A_Week" defaultMessage="Searches" /></p> */}
                                                            <p><span className="fas fa-search mr-1"></span><FormattedMessage id="Searches_A_Week" defaultMessage="Searches" /></p>
                                                            <p> <span className="icon-device_1"></span>
                                                                <span className="icon-device_2"></span>{JP?.device_limit + " " + deviceDisplay(JP?.device_limit)} </p>
                                                        </div>
                                                        : JP?.trial == 1
                                                            ? <div className="device_d">
                                                                <p><FormattedMessage id="CancelAnytime" defaultMessage="Cancel Anytime" /></p>
                                                            </div>
                                                            : JP?.id == JEWELER_SMALL_TEXT
                                                                ? <div className="device_d">
                                                                    <p>
                                                                        <span className="fas fa-search mr-1"></span>
                                                                        <FormattedMessage id="Searches_In_Month" defaultMessage="Searches" />
                                                                    </p>
                                                                    <p>
                                                                        <span className="icon-device_1"></span>
                                                                        <span className="icon-device_2"></span>
                                                                        {JP?.device_limit + " " + deviceDisplay(JP?.device_limit)}
                                                                    </p>
                                                                </div>
                                                                : <div className="device_d">
                                                                    <p><span className="fas fa-search mr-1"></span>{UNLIMITED_SEARCH}</p>

                                                                    <p> <span className="icon-device_1"></span>
                                                                        <span className="icon-device_2"></span>{JP?.device_limit + " " + deviceDisplay(JP?.device_limit)} </p>
                                                                </div>
                                                }
                                                <p className='redAlert'> {
                                                    JP?.features !== null ?
                                                        stringReplace(JP?.features)
                                                        : ""
                                                }</p>

                                                <div className="plan_price">
                                                    {
                                                        plan_period ?
                                                            <Fragment>
                                                                <span className="year_date"> /<FormattedMessage id="yr" /></span>
                                                                <h3>{currency}{JP?.yearly_price}</h3>
                                                                {/* <span className="offer_price">{savePercentage(JP?.monthly_price, JP?.yearly_price) || "0.00"}%</span> */}
                                                                <span className="offer_price">{JP?.yearly_price === 0 ? "0%" : `${savePercentage(JP?.monthly_price, JP?.yearly_price) || "0.00"}%`}</span>
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <span className="year_date"> /<FormattedMessage id="mo" /></span>
                                                                <h3>{currency}{JP?.monthly_price}</h3>
                                                            </Fragment>
                                                    }
                                                </div>
                                                {user || localStorage.getItem('isAuthenticated') ?
                                                    <Link to={url + '/plan-details'} className="btn btn-white"><FormattedMessage id="home.become_member" defaultMessage="Become a Member Now" /></Link>
                                                    :
                                                    <Link to={url + '/register'} className="btn btn-white"><FormattedMessage id="home.become_member" defaultMessage="Become a Member Now" /></Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Jeweler
