import { GET_CHATUSER_REQUEST, GET_CHATUSER_SUCCESS, GET_CHATUSER_FAIL,
    GET_MESSAGE_HISTORY_REQUEST,
    GET_MESSAGE_HISTORY_SUCCESS,
    GET_MESSAGE_HISTORY_FAIL,
    ADD_CHATUSER_REQUEST,
    ADD_CHATUSER_SUCCESS,
    ADD_CHATUSER_FAIL,
    GET_SHORTMSG_REQUEST,
    GET_SHORTMSG_SUCCESS,
    GET_SHORTMSG_FAIL,
    CHAT_ACTIVE_STATUS_REQUEST,
    CHAT_ACTIVE_STATUS_SUCCESS,
    CHAT_ACTIVE_STATUS_FAIL,
 } from '../constants/chatConstant';

export const getChatUserReducer = (state = { chatuser:null }, action) => {

    switch (action.type) {
        case GET_CHATUSER_REQUEST:
            return {
                loading: true,
                chatuser: null
            }
        case GET_CHATUSER_SUCCESS:
            return {
                loading: false,
                chatuser: action.payload
            }
        case GET_CHATUSER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state     
    }

}


export const getMessageHistoryReducer = (state = { messageHistory:null }, action) => {

    switch (action.type) {
        case GET_MESSAGE_HISTORY_REQUEST:
            return {
                loading: true,
                messageHistory: null
            }
        case GET_MESSAGE_HISTORY_SUCCESS:
            return {
                loading: false,
                messageHistory: action.payload
            }
        case GET_MESSAGE_HISTORY_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state     
    }

}


export const addChatUserReducer = (state = { addChatUser:null }, action) => {

    switch (action.type) {
        case ADD_CHATUSER_REQUEST:
            return {
                loading: true,
                addChatUser: null
            }
        case ADD_CHATUSER_SUCCESS:
            return {
                loading: false,
                addChatUser: action.payload
            }
        case ADD_CHATUSER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state     
    }

}
export const getshortMsgReducer = (state = { shortmsg:null }, action) => {

    switch (action.type) {
        case GET_SHORTMSG_REQUEST:
            return {
                loading: true,
                shortmsg: null
            }
        case GET_SHORTMSG_SUCCESS:
            return {
                loading: false,
                shortmsg: action.payload
            }
        case GET_SHORTMSG_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state     
    }

}
export const ChatActiveReducer = (state = { shortmsg:null }, action) => {

    switch (action.type) {
        case CHAT_ACTIVE_STATUS_REQUEST:
            return {
                loading: true,
                active_status: null
            }
        case CHAT_ACTIVE_STATUS_SUCCESS:
            return {
                loading: false,
                active_status: action.payload
            }
        case CHAT_ACTIVE_STATUS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state     
    }

}