import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from '../../constants';
import { url } from '../../config'; 
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import $ from 'jquery';
import ContactUsPopUp from '../HomePage/ContactUsPopUp';
import { FormattedMessage, useIntl } from 'react-intl';

const Footer = () => {
    const { user, loading } = useSelector(state => state.user);
    const handleContactUsPopUp= (event) => {
        $("#popup-click-confirm").trigger('click');
    }
    const handleResForm = (status) => {
        $("#dismiss-modal").trigger('click');
    }

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return (
        <footer className='footerNew'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-5 aboutNEW'>
                    <p><Link to="#" className="logo"> <img src={IMAGES.WHITE_LOGO} alt="" /></Link></p>
                    <Link className="CallNUmber"><img src={IMAGES.calliCon} alt="banner img" /> {"0530 235 9078"}</Link>
                    <p>
                        <FormattedMessage id="main.footer.footerdesc" defaultMessage="Become a member of Cevaheer, where more than 150 suppliers and more than 7 thousand member jewelers come together, and start sourcing smarter."/>
                    </p>
                </div>
                <div className='col-lg-7 FooterNewLink'>
                    <div className='row'>
                        <div className='col-6 col-md-3 col-lg-3'>
                            <h6><FormattedMessage id="main.footer.co" defaultMessage="Company"/></h6>
                            <ul>
                                <li><a href={"#"} 
                                    onClick={(e) => {
                                        e.preventDefault();
                                        scrollToSection('WHYCevaheers');
                                    }}>
                                    <FormattedMessage
                                            id="main.header.item1"
                                            defaultMessage="Why Cevaheer?"
                                        /></a>
                                </li>
                                <li><a href={"#"} 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            scrollToSection('pricings');
                                        }}> 
                                    <FormattedMessage id="main.header.item6"  defaultMessage="Packages"/></a>
                                </li>
                                <li><a href={"#"} 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            scrollToSection('testimonails');
                                        }}> 
                                    <FormattedMessage id="main.header.item7" defaultMessage="References"/></a>
                                </li>
                                <li> <a href={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            scrollToSection('Statis');
                                        }} >
                                     <FormattedMessage id="main.header.item5" defaultMessage="Statistics"/></a>
                                </li>
                                <li><Link onClick={handleContactUsPopUp}>
                                    <FormattedMessage id="main.header.membersupport" defaultMessage="Membership Support"/>
                                     </Link>
                               </li>
                            </ul>
                        </div>
                        <div className='col-6 col-md-3 col-lg-3'>
                            <h6><FormattedMessage id="main.footer.solutions" defaultMessage="Solutions"/></h6>
                            <ul>
                                <li><Link to="#">
                                        <FormattedMessage id="main.footer.teams" defaultMessage="Teams"/>
                                   </Link>
                                </li>
                                <li><Link to="#">
                                        <FormattedMessage id="main.footer.industries" defaultMessage="Industries"/>
                                   </Link></li>
                            </ul>
                        </div>
                        <div className='col-md-6 col-lg-6'>
                        <h6><FormattedMessage id="myaccount.contactus" defaultMessage="Contact Us"/></h6>

                            <Link className="CallNUmber"><img src={IMAGES.calliCon} alt="banner img" />{"0530 235 9078"}</Link>
                            <div className='footerJoinLink'>
                                <Link to={url + '/register'} className="btn btnRound_Line">
                                    <span>
                                        <FormattedMessage id="register.become-a-member" defaultMessage="Become a Member"/>
                                    </span>
                                </Link>
                                <Link to={url + '/register'} className="btn btnWHITE">
                                    <span>
                                        <FormattedMessage id="main.header.item8" defaultMessage="Get Started Free"/>
                                    </span>
                                </Link>
                            </div>
                            <div className='appstorelink'>
                            <a 
                                        className="d-block" 
                                        href="https://apps.apple.com/tr/app/cevaheer/id1193920501" 
                                        target="_blank" 
                                        rel="noopener noreferrer" // This is important for security reasons
                                    >
                                        <img 
                                            style={{ borderRadius: '10px', border: '1.2px solid #ffffff' }}   
                                            src={IMAGES.APP_STORE} 
                                            alt="App Store" // Always good to add alt text for accessibility
                                        />
                                    </a>
                                    <a 
                                        className="d-block" 
                                        href="https://play.google.com/store/apps/details?id=com.cevaheer.cevaheerapp" 
                                        target="_blank" 
                                        rel="noopener noreferrer" // Security measure
                                    >
                                        <img 
                                            style={{ borderRadius: '10px', border: '1.2px solid #ffffff' }}  
                                            src={IMAGES.GP_STORE} 
                                            alt="Google Play Store" // Add alt text for accessibility
                                        />
                                    </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            </div>
            <div className='copyRIghtNEW'>
                <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-5'>
                <Link to={url + '/privacy'} className> 
                        <FormattedMessage id="main.header.policy" defaultMessage="Privacy"/>
                </Link> 
                <span className='Fgap'>|</span>
                 <Link to={url + '/terms&conditions'}  className>            
                      <FormattedMessage id="main.footer.termuse" defaultMessage="Terms of Use and Sale"/>
                 </Link>
                </div>
                <div className='col-md-7 text-lg-right'><p> 
                    <FormattedMessage id="main.header.copyright" defaultMessage="@2021"/></p></div>
            </div>
            </div>
            </div>
            {
                <ContactUsPopUp 
                    message={useIntl().formatMessage({id: 'deleteAllProducts'})}
                    title={useIntl().formatMessage({id: 'Confirmation'})}
                    handleResForm={handleResForm} 
                />
            }
       </footer>
       
//         <footer className="mt-3 pt-5 border-top">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-md-3"><img src={IMAGES.WHITE_LOGO} alt="" /></div>
//                     <div className="col-md-5 text-center px-0">
//                         <ul>
//                             <li><a href={url + "#whyus"}>
//                                 <FormattedMessage
//                                     id="main.header.item1"
//                                     defaultMessage="Why Cevaheer?"
//                                 />
//                             </a></li>
//                             <li><a href={url + "#pricings"}>
//                                 <FormattedMessage
//                                     id="main.header.item2"
//                                     defaultMessage="Pricing"
//                                 />
//                             </a></li>
//                             <li><a href={url + "#testimonails"}>
//                                 <FormattedMessage
//                                     id="main.header.item3"
//                                     defaultMessage="Testimonials"
//                                 />
//                             </a></li>
//                             <li><a href={url + "#nUsers"}>
//                                 <FormattedMessage
//                                     id="main.header.item4"
//                                     defaultMessage="Number of Members"
//                                 />
//                             </a></li>
//                         </ul>
//                     </div>
//                     <div className="col-md-4 text-right">
//                         <ul>
//                             <li><Link onClick={handleContactUsPopUp}>
//                                 <FormattedMessage
//                                     id="main.header.contactsales"
//                                     defaultMessage="Contact Sales"
//                                 />
//                             </Link></li>

//                         {
//                             !user && !localStorage.getItem('isAuthenticated') &&
//                             <Fragment>
//                             <li><Link to={url + '/login'}>
//                                 <FormattedMessage
//                                     id="main.header.signin"
//                                     defaultMessage="Sign In"
//                                 />
//                             </Link></li>
//                             <li><Link to={url + '/register'}>
//                                 <FormattedMessage
//                                     id="main.header.signup"
//                                     defaultMessage="Sign Up"
//                                 />
//                             </Link></li>
//                             </Fragment>
// }   
//                         </ul>
//                     </div>
//                 </div>

//                 <div className="row btmRow">
//                     <div className="col-md-12 text-center">
//                         <ul>
//                             <li>
//                                 <FormattedMessage
//                                     id="main.header.copyright"
//                                     defaultMessage="@2021"
//                                 />
//                             </li>
//                             <li><Link to={url + '/terms&conditions'}>
//                                 <FormattedMessage
//                                     id="main.header.term"
//                                     defaultMessage="Terms and condition"
//                                 />
//                             </Link></li>
//                             <li><Link to={url + '/privacy'}>
//                                 <FormattedMessage
//                                     id="main.header.policy"
//                                     defaultMessage="Privacy"
//                                 />
//                             </Link></li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </footer>
    )
}

export default Footer
