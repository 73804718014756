import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { IMAGES } from '../../constants'

const NumberOfMember = () => {

    const { Home } = useSelector(state => state.home)

    console.log("Home",Home);

    return (
        <section className='HWE_statistical' id="Statis">
            <div className='container-fluid'>
                <div className='row align-items-end'>
                    <div className='col-lg-5 pr-lg-5'>
                        <h2 className='NewTitle whiteText'>{Home && Home?.homePage && Home?.homePage?.nom_sect_titl2}</h2>
                        <p className='HWEsubText'>{Home && Home?.homePage && Home?.homePage?.nom_sect_desc}
                        </p>
                    </div>
                    <div className='col-lg-7'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='Statistical'>
                                    <img src={IMAGES.newICOn10} alt="banner img" />
                                    <p className='Hcount'>{Home && Home?.homePage && Home?.homePage?.nom_col_one_value}</p>
                                    <p>{Home && Home?.homePage && Home?.homePage?.nom_col_one_text}</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='Statistical'>
                                    <img src={IMAGES.newICOn11} alt="banner img" />
                                    <p className='Hcount'>{Home && Home?.homePage && Home?.homePage?.nom_col_four_value}</p>
                                    <p>{Home && Home?.homePage && Home?.homePage?.nom_col_four_text}</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='Statistical'>
                                    <img src={IMAGES.newICOn12} alt="banner img" />
                                    <p className='Hcount'>{Home && Home?.homePage && Home?.homePage?.nom_col_two_value}</p>
                                    <p>{Home && Home?.homePage && Home?.homePage?.nom_col_two_text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NumberOfMember
