import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { logout } from '../../actions/userActions';
import { IMAGES } from '../../constants';
import { url } from '../../config'
import { Context } from "../Wrapper";
import { handleLangList, handleSortBy, handlephoneList } from '../../helper';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import ContactUsPopUp from '../HomePage/ContactUsPopUp';
import { FormattedMessage, useIntl } from 'react-intl';
const $ = require('jquery');

const Header = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const alert = useAlert();
    console.log("location===", location)
    const { user, loading } = useSelector(state => state.user);
    const context = useContext(Context);
    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('socket_group_id')

        alert.success('Logout successful')
        window.location.href = url;
    }

    const handleAcendingFilter = (value) => {
        //setFilterDisplayName(displayname)
        $('.sort_by_list').css({
            display: "none"
        })
        context.selectLanguage(value)
        // dispatch(myProductFilterByAscending(category_slug, value, type))
    }

    useEffect(() => {
        if (user && (location?.pathname != '/terms&conditions' || location?.pathname != '/terms&privacy' || location?.pathname != '/electronic-message')) {
            window.location.href = url + '/category/diamonds';
        }
    }, [location?.pathname])

    const handleContactUsPopUp = (event) => {
        $("#popup-click-confirm").click();
    }
    const handleResForm = (status) => {
        $("#dismiss-modal").click();
    }

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <div className="bg_img newHeader">
            <header className="homes">
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <nav className="navbar navbar-expand-lg py-3 px-0 w-100">
                        <Link to={url} className="logo"> <img src={IMAGES.WHITE_LOGO} alt="" /></Link>
                        <div className='d-flex d-lg-none ml-auto'>
                            {
                                user || localStorage.getItem('isAuthenticated') ? (
                                    <div className=" right order-md-2 order-3 filter_search_right">
                                        <div className="btn-groups search_cat_top nav_btns">
                                            <div className="form-group pb-0 sort_by_box BTnWhiteLine btnrounded">
                                                <p className="sort_b_h text-right lang_p" onClick={handleLangList} >
                                                    {/* <img src={IMAGES.earth} alt="" /> */}
                                                    <i className="fa fa-globe"></i>
                                                </p>
                                                <div className=" lang_list" >
                                                    {
                                                        <ul>
                                                            <li className={context.locale == 'tr' && 'active'} onClick={() => handleAcendingFilter('tr')}><i className="fa fa-globe"></i> Turkish</li>
                                                            <li className={context.locale == 'en' && 'active'} onClick={() => handleAcendingFilter('en')} ><i className="fa fa-globe"></i> English</li>
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="form-group pb-0 sort_by_box BTnWhiteLine btnrounded">
                                                <p className="sort_b_h text-right lang_p" onClick={handlephoneList}>
                                                    <i className="fas fa-phone"></i>
                                                </p>
                                                <div className="phone_lists" >
                                                    {
                                                        <ul>
                                                            <li className=""><i><img src={url + "/static/media/turkey.009996bfa350133ea7d1.png"} alt="Country Flag" width="25px" /></i> {"0530 235 9078"}</li>
                                                        </ul>
                                                    }
                                                </div>
                                            </div> */}
                                            <div className="form-group pb-0 sort_by_box BTnWhiteLine btnrounded">
                                                <p className="sort_b_h text-right lang_p" onClick={handlephoneList}>
                                                    <i className="fas fa-phone"></i>
                                                </p>
                                                <div className="phone_lists" style={{ display: 'none' }}>
                                                    <ul>
                                                        <li>
                                                            <a className='text-nowrap d-flex justify-content-center align-items-center' href="tel:05302359078">
                                                                <img className='mr-2' src={url + "/static/media/turkey.009996bfa350133ea7d1.png"} alt="Country Flag" width="25px" />
                                                                {/* <span className='align-middle' >
                                                            </span> */}
                                                                {"0530 235 9078"}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <Link to={url + "/profile"} className="MyProfiles">
                                                <FormattedMessage
                                                    id="main.header.myprofile"
                                                    defaultMessage="My Profile"
                                                />
                                            </Link>
                                            <Link to={url + "/"} onClick={handleLogout} className="signups">
                                                <FormattedMessage
                                                    id="main.header.logout"
                                                    defaultMessage="Logout"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                ) : !loading &&
                                <Fragment>
                                    <div className="right order-md-2 order-3 filter_search_right">
                                        <div className="btn-groups search_cat_top nav_btns">
                                            <div className="form-group pb-0 sort_by_box BTnWhiteLine btnrounded">
                                                <p className="sort_b_h text-right lang_p" onClick={handleLangList} >
                                                    {/* <img src={IMAGES.earth} alt="" /> */}
                                                    <i className="fa fa-globe"></i>
                                                </p>
                                                <div className=" lang_list" >
                                                    {
                                                        <ul>
                                                            <li className={context.locale == 'tr' && 'active'} onClick={() => handleAcendingFilter('tr')}><i className="fa fa-globe"></i> Turkish</li>
                                                            <li className={context.locale == 'en' && 'active'} onClick={() => handleAcendingFilter('en')} ><i className="fa fa-globe"></i> English</li>
                                                        </ul>
                                                    }
                                                </div>
                                            </div>

                                            {/* <div className="form-group pb-0 sort_by_box BTnWhiteLine btnrounded">
                                                <p className="sort_b_h text-right lang_p" onClick={handlephoneList}>
                                                    <i class="fas fa-phone"></i>
                                                </p>
                                                <div className="phone_lists" >
                                                    {
                                                        <ul>
                                                            <li className=""><i>
                                                                <img
                                                                    src={url + "/static/media/turkey.009996bfa350133ea7d1.png"}
                                                                    alt="Country Flag"
                                                                    width="25px" />
                                                            </i> {"0530 235 9078"}</li>
                                                        </ul>
                                                    }
                                                </div>
                                            </div> */}

                                            <div className="form-group pb-0 sort_by_box BTnWhiteLine btnrounded">
                                                <p className="sort_b_h text-right lang_p" onClick={handlephoneList}>
                                                    <i className="fas fa-phone"></i>
                                                </p>
                                                <div className="phone_lists" style={{ display: 'none' }}>
                                                    <ul>
                                                        <li>
                                                            <a className='text-nowrap d-flex justify-content-center align-items-center' href="tel:05302359078">
                                                                <img className='mr-2' src={url + "/static/media/turkey.009996bfa350133ea7d1.png"} alt="Country Flag" width="25px" />
                                                                {/* <span className='align-middle' >
                                                            </span> */}
                                                                {"0530 235 9078"}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <Link className="BTnWhiteLine" to={url + "/login"}>
                                                <FormattedMessage
                                                    id="main.header.signin"
                                                    defaultMessage="Sign In"
                                                />
                                            </Link>
                                        </div>


                                    </div>
                                </Fragment>
                            }
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        {/* 
                        <div className="text-center header-nav">
                        <ul>
                            <li><a href={url + "#whyus"}>
                                <FormattedMessage
                                    id="main.header.item1"
                                    defaultMessage="Why Cevaheer?"
                                />
                            </a></li>
                            <li><a href={url + "#pricings"}>
                                <FormattedMessage
                                    id="main.header.item2"
                                    defaultMessage="Pricing"
                                />
                            </a></li>
                            <li><a href={url + "#testimonails"}>
                                <FormattedMessage
                                    id="main.header.item3"
                                    defaultMessage="Testimonials"
                                />
                            </a></li>
                            <li><a href={url + "#Statis"}>
                                <FormattedMessage
                                    id="main.header.item4"
                                    defaultMessage="Number of Members"
                                />
                            </a></li>
                        </ul>
                        </div> */}

                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav list-style-none header-menus pr-4">
                                <li className="nav-item active">
                                    <a href={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            scrollToSection('WHYCevaheers');
                                        }}
                                        class="scroll-link-nav-bar">
                                        <FormattedMessage
                                            id="main.header.item1"
                                            defaultMessage="Why Cevaheer?"
                                        />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            scrollToSection('pricings');
                                        }}
                                        class="scroll-link-nav-bar">
                                        <FormattedMessage
                                            id="main.header.item6"
                                            defaultMessage="Packages"
                                        />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            scrollToSection('testimonails');
                                        }}
                                        class="scroll-link-nav-bar">
                                        <FormattedMessage
                                            id="main.header.item7"
                                            defaultMessage="Tesimonial"
                                        />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            scrollToSection('Statis');
                                        }}
                                        class="scroll-link-nav-bar">
                                        <FormattedMessage
                                            id="main.header.item5"
                                            defaultMessage="Statistics"
                                        />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <Link to='#' onClick={handleContactUsPopUp}>
                                        <FormattedMessage id="main.header.membersupport" defaultMessage="Membership Support" />
                                    </Link>
                                </li>
                            </ul>
                            <div className='mobileExtra d-lg-none'>
                                <Link className="BTnWhiteLine" to={url + "/register"}>
                                    <FormattedMessage
                                        id="register.become-a-member"
                                        defaultMessage="Become a Member"
                                    />
                                </Link>
                                <Link className="BTnWhite" to={url + "/register"}>
                                    <FormattedMessage
                                        id="main.header.item8"
                                        defaultMessage="Get Started Free"
                                    />
                                </Link>
                            </div>
                        </div>

                        {
                            user || localStorage.getItem('isAuthenticated') ? (
                                <div className=" right order-md-2 order-3 filter_search_right d-none d-lg-block">
                                    <div className="btn-groups search_cat_top nav_btns">
                                        <div className="form-group pb-0 sort_by_box" >
                                            <p className="sort_b_h text-right lang_p" onClick={handleLangList} ><b className="mr-2"><i className="fa fa-globe"></i></b> </p>
                                            <div className="lang_list">
                                                {
                                                    <ul>
                                                        <li className={context.locale == 'en' && 'active'} onClick={() => handleAcendingFilter('en')} ><i className="fa fa-globe"></i> English</li>
                                                        <li className={context.locale == 'tr' && 'active'} onClick={() => handleAcendingFilter('tr')}><i className="fa fa-globe"></i> Turkish</li>
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                        <Link to={url + "/contact-us"}>
                                            <FormattedMessage
                                                id="main.header.contactsales"
                                                defaultMessage="Contact Sales"
                                            />
                                        </Link>
                                        <Link to={url + "/profile"} className="MyProfiles">
                                            <FormattedMessage
                                                id="main.header.myprofile"
                                                defaultMessage="My Profile"
                                            />
                                        </Link>
                                        <Link to={url + "/"} onClick={handleLogout} className="signups">
                                            <FormattedMessage
                                                id="main.header.logout"
                                                defaultMessage="Logout"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            ) : !loading &&
                            <Fragment>
                                <div className="right order-md-2 order-3 filter_search_right d-none d-lg-block">
                                    <div className="btn-groups search_cat_top nav_btns">
                                        <div className="form-group pb-0 sort_by_box BTnWhiteLine btnrounded"   >
                                            <p className="sort_b_h text-right lang_p" onClick={handleLangList} >
                                                {/* <img src={IMAGES.earth} alt="" /> */}
                                                <i className="fa fa-globe"></i>
                                            </p>
                                            <div className=" lang_list" >
                                                {
                                                    <ul>
                                                        <li className={context.locale == 'tr' && 'active'} onClick={() => handleAcendingFilter('tr')}><i className="fa fa-globe"></i> Turkish</li>
                                                        <li className={context.locale == 'en' && 'active'} onClick={() => handleAcendingFilter('en')} ><i className="fa fa-globe"></i> English</li>
                                                    </ul>
                                                }
                                            </div>
                                        </div>

                                        {/* <div className="form-group pb-0 sort_by_box BTnWhiteLine btnrounded">
                                            <p className="sort_b_h text-right lang_p" onClick={handlephoneList}>
                                                <i class="fas fa-phone"></i>
                                            </p>
                                            <div className="phone_lists" >
                                                {
                                                    <ul>
                                                        <li className=""><i><img src={url + "/static/media/turkey.009996bfa350133ea7d1.png"} alt="Country Flag" width="25px" /></i> {"0530 235 9078"}</li>
                                                    </ul>
                                                }
                                            </div>
                                        </div> */}

                                        <div className="form-group pb-0 sort_by_box BTnWhiteLine btnrounded">
                                            <p className="sort_b_h text-right lang_p" onClick={handlephoneList}>
                                                <i className="fas fa-phone"></i>
                                            </p>
                                            <div className="phone_lists" style={{ display: 'none' }}>
                                                <ul>
                                                    <li>
                                                        <a className='text-nowrap d-flex justify-content-center align-items-center' href="tel:05302359078">
                                                            <img className='mr-2' src={url + "/static/media/turkey.009996bfa350133ea7d1.png"} alt="Country Flag" width="25px" />
                                                            {/* <span className='align-middle' >
                                                            </span> */}
                                                            {"0530 235 9078"}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <Link className="BTnWhiteLine" to={url + "/login"}>
                                            <FormattedMessage
                                                id="main.header.signin"
                                                defaultMessage="Sign In"
                                            />
                                        </Link>
                                        <Link className="BTnWhiteLine" to={url + "/register"}>
                                            <FormattedMessage
                                                id="register.become-a-member"
                                                defaultMessage="Become a Member"
                                            />
                                        </Link>
                                        <Link className="BTnWhite" to={url + "/register"}>
                                            <FormattedMessage
                                                id="main.header.item8"
                                                defaultMessage="Get Started Free"
                                            />
                                        </Link>
                                        {/* <Link onClick={handleContactUsPopUp}>
                                            <FormattedMessage
                                                id="main.header.contactsales"
                                                defaultMessage="Contact Sales"
                                            />
                                        </Link> */}


                                        {/* <Link to={url + "/register"} className="signups">
                                            <FormattedMessage
                                                id="main.header.signup"
                                                defaultMessage="Sign Up"
                                            />
                                        </Link> */}
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </nav>
                </div>
            </header>
            <button
                style={{ display: 'none' }}
                className="btn-black active-all"
                id="popup-click-confirm"
                data-toggle="modal"
                data-target="#contact-us">
                pop up
            </button>
            {
                <ContactUsPopUp
                    message={useIntl().formatMessage({ id: 'deleteAllProducts' })}
                    title={useIntl().formatMessage({ id: 'Confirmation' })}
                    handleResForm={handleResForm}
                />
            }
        </div>
    )
}

export default Header

