import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import Pricing from '../HomePage/Pricing'
import Testimonial from '../HomePage/Testimonial'
import NumberOfMember from '../HomePage/NumberOfMember'
import WhyCevaheer from '../HomePage/WhyCevaheer'

import { Link } from 'react-router-dom';
import { url } from '../../config';
import { FormattedMessage, useIntl } from 'react-intl';

import { Fragment, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useSelector, useDispatch } from 'react-redux';
import { getHomePage } from '../../actions/HomePageActions';
import mixpanel from 'mixpanel-browser';
import { IMAGES } from '../../constants'

import $ from 'jquery';
const NewHome = () => {
    const alert = useAlert()
    const dispatch = useDispatch()

    useEffect(() => {
        mixpanel.track('Home')
    }, [])

    useEffect(async () => {
        await dispatch(getHomePage())
    }, [dispatch])

    const homeState = useSelector(state => state.home)
    const handleContactUsPopUp = (event) => {
        $("#popup-click-confirm").trigger('click');
    }

    if (homeState?.error) {
        alert.error(homeState?.error);
        return
    }

    return (
        <>
            <Header />
            <main className='newHOme'>
                <section className='HWETopbanner'>
                    <div className='container-fluid'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-11 col-xl-9'>
                                <h1 dangerouslySetInnerHTML={{ __html: homeState?.Home?.homePage?.banner_text || '' }}></h1>
                                <p dangerouslySetInnerHTML={{ __html: homeState?.Home?.homePage?.banner_sub_text || '' }}></p>
                                <div className='HweBTNGroup'>
                                    <a href={url + "/register"} className="btn btnFUll_Bg">
                                        <FormattedMessage id="main.header.item8" defaultMessage="Get Started Free" /></a>
                                    <a href={url + "#pricings"} className="btn btnRound_Line">
                                        <span>
                                            <Link to='#' onClick={handleContactUsPopUp}>
                                                <FormattedMessage id="main.header.membersupport" defaultMessage="Membership Support" />
                                            </Link>
                                            {/* <FormattedMessage id="home.demo"  defaultMessage="Get a demo"/> */}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='HWETopbannerImage'>
                            <img src={IMAGES.bannerIMG} alt="banner img" />
                        </div>
                    </div>
                </section>

                <WhyCevaheer />

                <section className='TrastWorth'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-8 mx-auto text-center'>
                                <h2 className='NewTitle'><FormattedMessage id="home.item11" defaultMessage="Trustworthy" /></h2>
                                <p className='HWEsubText'>"<FormattedMessage id="home.item12" defaultMessage="Cevaheer delivers tailored solutions that drive business success. Our expert team understands your unique needs, offering innovative services to help you achieve your goals." />"</p>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-lg-10 col-xl-10'>
                                <div className='trustBOX'>
                                    <div className='row'>
                                        <div className='col-md-7 pt-5 px-5'>
                                            <img src={IMAGES.newICOn4} alt="banner img" />
                                            <h6><FormattedMessage id="home.item13" defaultMessage="Safe System" /></h6>
                                            <p>"<FormattedMessage id="home.item14" defaultMessage="Cevaheer delivers tailored solutions that drive business success. Our expert team understands your unique needs, offering innovative services to help you achieve your goals." />"</p>
                                        </div>
                                        <div className='col-md-5 HWEimgRIght'>
                                            <img src={IMAGES.TrustIMG1} alt="banner img" />
                                        </div>
                                    </div>
                                    <span className='ArrowICOn'><img src={IMAGES.arrowROund} alt='' /></span>
                                </div>
                            </div>
                            <div className='col-lg-10 col-xl-10 offset-lg-2 offset-xl-2'>
                                <div className='trustBOX'>
                                    <div className='row'>

                                        <div className='col-md-7 pt-5 pl-5 pr-5 order-lg-2'>
                                            <img src={IMAGES.newICOn5} alt="banner img" />
                                            <h6><FormattedMessage id="home.item15" defaultMessage="Verified Suppliers" /></h6>
                                            <p>"<FormattedMessage id="home.item16" defaultMessage="Cevaheer partners with only the most trusted and verified suppliers to ensure the highest quality of products and services for our clients." />"</p>
                                        </div>
                                        <div className='col-md-5 HWEimgLeft'>
                                            <img src={IMAGES.TrustIMG2} alt="banner img" />
                                        </div>
                                    </div>
                                    <span className='ArrowICOn'><img src={IMAGES.arrowROund} alt='' /></span>
                                </div>
                            </div>
                            <div className='col-lg-10 col-xl-10'>
                                <div className='trustBOX'>
                                    <div className='row'>
                                        <div className='col-md-7 pt-5 px-5'>
                                            <img src={IMAGES.newICOn6} alt="banner img" />
                                            <h6><FormattedMessage id="home.item17" defaultMessage="Choice of Industry Leaders" /></h6>
                                            <p>"<FormattedMessage id="home.item18" defaultMessage="Cevaheer is the preferred choice of industry leaders seeking innovative, reliable, and effective solutions." />"</p>
                                        </div>
                                        <div className='col-md-5 HWEimgRIght'>
                                            <img src={IMAGES.TrustIMG3} alt="banner img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='HWECompetitive'>
                    <div className='container-fluid'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div className='CompetitiveLeft'>
                                    <ul>
                                        <li>
                                            <img src={IMAGES.newICOn7} alt="banner img" />
                                            <p><FormattedMessage id="home.item19" defaultMessage="Direct Supply" /></p>
                                        </li>
                                        <li>
                                            <img src={IMAGES.newICOn8} alt="banner img" />
                                            <p><FormattedMessage id="home.item20" defaultMessage="No Intermediary" /></p>
                                        </li>
                                        <li>
                                            <img src={IMAGES.newICOn9} alt="banner img" />
                                            <p><FormattedMessage id="home.item21" defaultMessage="Real Time Market" /><br /><FormattedMessage id="home.item22" defaultMessage="Intelligence" /></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='CompetitiveRight'>
                                    <h2 className='NewTitle whiteText'><FormattedMessage id="home.item23" defaultMessage="Competitive" /></h2>
                                    <p className='HWEsubText'><FormattedMessage id="home.item24" defaultMessage="At Cevaheer, we empower businesses to stay competitive in today’s fast-paced market. Our solutions are designed to help you outperform your competition by leveraging the latest technologies, strategies, and industry insights." /></p>
                                    <Link to={url + '/register'} className="btn btnWHITE"><span><FormattedMessage id="home.item25" defaultMessage="Try for Free" /></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Pricing />
                <NumberOfMember />
                <Testimonial />


            </main>
            <Footer />
        </>
    )
}

export default NewHome