import React from 'react';
import { useSelector } from 'react-redux';
import { IMAGES } from '../../constants';
import { ImageUrl } from '../../helper';
import { FormattedMessage } from 'react-intl';

const Testimonial = () => {

    const { Home } = useSelector(state => state.home)

    return (
        <section id="testimonails" className="testimonal testimonialNEW py-5">
            <div className='container-fluid'>
                {/* <h2 className="mt-5 mb-3 text-center black fm font-40 ">{Home && Home?.homePage && Home?.homePage?.testimonials_sect_title}</h2> */}
                <div className='row text-center'>
                    <div className='col-lg-10 mx-auto'>
                        <h2 className='NewTitle'><FormattedMessage id="home.item31" defaultMessage="What Do Jewelers Think?" /></h2>
                        <p className='HWEsubText'><FormattedMessage id="home.item32" defaultMessage="Jewelers trust Cevaheer for its exceptional quality and reliable solutions tailored to the jewelry industry. Many have praised our commitment to precision, attention to detail, and timely delivery of services." /></p>
                    </div>
                </div>

                <div id="carouselExampleControls" className="carousel slide mt-5" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleControls" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleControls" data-slide-to="1"></li>
                        <li data-target="#carouselExampleControls" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        {Home && Home.testimonials && Home.testimonials.map((testiO, key) => {
                            return (
                                <div key={key} className={key === 0 ? "carousel-item active" : "carousel-item"}>
                                    <div className="row justify-content-md-center c_index">
                                        {testiO && testiO.map((testi, key2) => {
                                            return (
                                                <div key={key2} className="col-md-4 d-flex">
                                                    <div className="tblocks">
                                                        <div className="user_car">
                                                            <div className='TEestNames'>
                                                                <h5>{testi.posted_by}</h5>
                                                            </div>
                                                            <img src={ImageUrl(testi.image)} alt={testi.posted_by} />
                                                        </div>
                                                        <div className='userCOmment'>
                                                            <div className='userRating'><img src={IMAGES.ratingIMG} alt="banner img" /></div>
                                                            <p>{testi?.description && testi?.description.substr(0, 300)}</p>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonial