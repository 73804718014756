import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { getCategory } from '../../../actions/categoryAction';
import { url } from '../../../config';
import { getLastURL, getSecondLastURL } from '../../../helper';
import { FormattedMessage } from 'react-intl';
import { SoryByFilterAction } from '../../../actions/productActions';
import { ALL_PRODUCTS_FAIL } from '../../../constants/productConstants';

const ProtectedNav = () => {

    const dispatch = useDispatch()
    const location = useLocation()

    const { category } = useSelector(state => state.categories);
    const path = getLastURL(location);
    const path2 = getSecondLastURL(location);

    // useEffect(() => {
    //     if(!category)
    //     {
    //         dispatch(getCategory());
    //     }
    // }, [category])
    const handleTabChange = () => {
        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: null
        })
        dispatch(SoryByFilterAction({}))
    }
    return (
        <div className="header-menu">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <nav className="navbar navbar-default px-0">

                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a className="navbar-brand visible-xs" href="/">MENU</a>
                            </div>
                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul className="nav navbar-nav">
                                    {
                                        category && category.categories && category.categories.map((category) => {
                                            return (
                                                <li>
                                                    <a onClick={() => handleTabChange()}>
                                                        <Link className={path2 === 'category' && category.slug === path || path2 === category.slug ? "active" : null} to={url + '/category/' + category.slug}>
                                                            <FormattedMessage
                                                                id={"category." + category.slug}
                                                                defaultMessage={category.name}
                                                            />
                                                        </Link>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProtectedNav
