import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { DownloadPdfWithFilterAction, getAttributes } from '../../../actions/productActions';
import { getSuppliers } from '../../../actions/productActions';
import $ from 'jquery';
import { getLastURL } from '../../../helper';
import { FINISH_COMB } from '../../../constants/index';

import { getAllProductsFilter } from '../../../actions/productActions';
import { ShapeImageUrl, arraysHaveSameValues } from '../../../helper';
import { CaratDiamondJson } from '../../../utils/json';
import { FormattedMessage, useIntl } from 'react-intl';
import AsyncSelect from 'react-select/async'
import { makeRequestPost } from '../../../utils/apiHelper';
import Select from 'react-select'
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import './model.css'
import { ALL_PRODUCTS_FAIL } from '../../../constants/productConstants';
import mixpanel from 'mixpanel-browser';


const Sidebar = ({ props, handleEditPage, tabChangeHandler }) => {

    const { sellers } = useSelector(state => state.getSelersReducer);
    // const sellers = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' },
    //   ];

    const dispatch = useDispatch();
    const location = useLocation();

    const pathname = getLastURL(location);

    const { attributes } = useSelector(state => state.attributes);

    const [shape, setShape] = useState([]);
    const [clarityCheck, setClarityCheck] = useState(new Array(attributes && attributes.clarity && attributes.clarity.length).fill(false));
    const [clarity, setClarity] = useState([]);
    const [cutCheck, setCutCheck] = useState(new Array(attributes && attributes.cut && attributes.cut.length).fill(false));
    const [cut, setCut] = useState([]);
    const [polishCheck, setPolishCheck] = useState(new Array(attributes && attributes.polish && attributes.polish.length).fill(false))
    const [polish, setPolish] = useState([]);
    const [symmetryCheck, setSymmetryCheck] = useState(new Array(attributes && attributes.symmetry && attributes.symmetry.length).fill(false));
    const [symmetry, setSymmetry] = useState([]);
    const [fluoresenceCheck, setFluoresenceCheck] = useState(new Array(attributes && attributes.fluorescence && attributes.fluorescence.length).fill(false));
    const [fluoresence, setFluoresence] = useState([]);
    const [certificateCheck, setCertificateCheck] = useState(new Array(attributes && attributes.certificate && attributes.certificate.length).fill(false));
    const [certificate, setCertificate] = useState([]);
    const [defaultSeller, setDefaultSeller] = useState([]);
    const [carat, setCarat] = useState({
        from: "",
        to: ""
    })
    const [price, setPrice] = useState({
        from: "",
        to: ""
    })
    const [color, setColor] = useState([]);
    const [sellerList, setSellerList] = useState([]);
    const [intensityCheck, setIntensityCheck] = useState(new Array(attributes && attributes.fancy_intensity && attributes.fancy_intensity.length).fill(false));
    const [fancy_color_intensity, setFancyColorIntensity] = useState([]);
    const [overtoneCheck, setOvertoneCheck] = useState(new Array(attributes && attributes.fancy_overtone && attributes.fancy_overtone.length).fill(false));
    const [fancy_color_overtone, setFancyColorOvertone] = useState([]);
    const [fancycolorCheck, setFancyColorCheck] = useState(new Array(attributes && attributes.fancy_color && attributes.fancy_color.length).fill(false));
    const [fancy_color, setFancyColor] = useState([]);
    const [selectColor, setSelectColor] = useState(false);

    const [finishCheck, setFinishCheck] = useState(false, false, false);
    const [finish, setFinish] = useState([]);
    const [supplierCheck, setSupplierCheck] = useState(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false));
    const [supplier, setSupplier] = useState([]);

    const [milkyCheck, setMilkyCheck] = useState(new Array(attributes && attributes.milky && attributes.milky.length).fill(false));
    const [milky, setMilky] = useState([]);

    const [statusCheck, setStatusCheck] = useState(new Array(attributes && attributes.availability_status && attributes.availability_status.length).fill(false));
    const [availability_status, setAvailabilityStatus] = useState([]);

    const [selectBgm, setSelectBgm] = useState(false);
    const [checkedBoxId, setCheckedBoxId] = useState([]);
    const [allAttributesArray, setAllAttributesArray] = useState([]);

    const [caratcheckboxCheck, setCaratCheckboxCheck] = useState(new Array(CaratDiamondJson && CaratDiamondJson.length).fill(false))

    const filter = {
        shapes: shape,
        clarity: clarity,
        cut: cut,
        polish: polish,
        symmetry: symmetry,
        fluorescence: fluoresence,
        certificate: certificate,
        carat: carat,
        price: price,
        color: color,
        fancy_color: fancy_color,
        fancy_intensity: fancy_color_intensity,
        fancy_overtone: fancy_color_overtone,
        finish: finish,
        seller: supplier,
        is_fancy: selectColor,
        seller: sellerList,
        milky: milky,
        no_BGM: selectBgm,
        status: checkedBoxId
    };

    useEffect(() => {
        const fetch = async () => {
            await dispatch(getAttributes('diamonds'))
            await dispatch(getSuppliers(''))
            sessionStorage.removeItem('discountDetails')

            const searchFilter = JSON.parse(sessionStorage.getItem('searchIdFilter'))
            const isSellerFilter = sessionStorage.getItem('isSellerFilter')
            if (searchFilter && isSellerFilter) {
                let userId = []
                searchFilter.map((d) => {
                    userId.push(d.value)
                })
                filter.seller = userId

                setDefaultSeller(searchFilter)

                mixpanel.track('Diamond_filter Jan25');
                console.log('fitler called--------------');
                await dispatch(getAllProductsFilter(pathname, filter));

                sessionStorage.removeItem('isSellerFilter')
                sessionStorage.removeItem('searchIdFilter')
            } else {
                sessionStorage.removeItem('isSellerFilter')
                sessionStorage.removeItem('searchIdFilter')
            }
        }
        fetch()

    }, [dispatch])

    const loadMoreDefault = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr' ? 'Daha fazla gör' : 'See More';
        let see_less = localStorage.getItem('lang') == 'tr' ? 'Daha Az Gör' : 'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; ' + see_more)
        } else {
            $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; ' + see_less)
        }
    }

    const loadMoreShape = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr' ? 'Daha fazla gör' : 'See More';
        let see_less = localStorage.getItem('lang') == 'tr' ? 'Daha Az Gör' : 'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            // $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .c_hide').attr('style', 'display: none !important;')
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; ' + see_more)
        } else {
            // $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').attr('style', 'display: inline-block !important;')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; ' + see_less)
        }
    }


    const handleFilter = async (e) => {
        e.preventDefault();
        mixpanel.track('Diamond_filter Jan25');
        handleEditPage(0)
        tabChangeHandler()

        filter.is_fancy = selectColor
        filter.no_BGM = selectBgm
        filter.seller = sellerList
        /* if(shape.includes(35) || (shape.length == 0)){
            filter.cut = [];
        } */
        // const discountDetails = JSON.parse(sessionStorage.getItem('discountDetails'))
        // dispatch(getAllProductsFilter(pathname, filter, 0, discountDetails));
        console.log("filter....>>", filter);
        mixpanel.track('Diamond_filter Jan25');
        dispatch(getAllProductsFilter(pathname, filter));
        dispatch(DownloadPdfWithFilterAction({ filter }))

        await $('.paginationBttns ').removeClass('paginationActive')
        await $('.paginationBttns li').siblings().removeClass("paginationActive");
    }

    const handleFilterShape = (e, id) => {
        e.preventDefault();
        console.log("shap id is---", id, shape.includes(id));
        if (!shape.includes(id)) {
            console.log("shap id not included---", id);

            setShape([...shape, id]);
        } else {
            shape.splice(shape.indexOf(id), 1);
            setShape([...shape]);
        }

        if ($('.shape-items-show li.shapec_' + id).hasClass('active')) {
            $('.shape-items-show li.shapec_' + id).removeClass('active')

        } else {
            $('.shape-items-show li.shapec_' + id).addClass('active')
        }
    }

    const handleFilterClarity = (position, id) => {

        const updatedClarity = clarityCheck && clarityCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setClarityCheck(updatedClarity);

        if (!clarity.includes(id)) {
            setClarity([...clarity, id]);
        } else {
            clarity.splice(clarity.indexOf(id), 1);
        }
    }

    const handleFilterFinish = (position, id, name) => {
        // const boolValue = !$("#"+id).val();
        //let boolValue = false;
        const updateFinish = finishCheck && finishCheck.map((item, index) => {

            if (index === position) {

                return !item;
            } else {
                return item;
            }
        });
        //Kanhaiya code
        // if(name === 'VG-')
        // {
        //     setCutCheck(new Array(attributes && attributes.cut && attributes.cut.length).fill(true));
        //     setPolishCheck(new Array(attributes && attributes.polish && attributes.polish.length).fill(true));
        //     setSymmetryCheck(new Array(attributes && attributes.symmetry && attributes.symmetry.length).fill(true));
        // }

        // if(name === '3X')
        // {
        //     setCutCheck(new Array(attributes && attributes.cut && attributes.cut.fill(true, 1, 2)));
        //     //setCutCheck(temp);
        // }
        //kanhaiya code
        setFinishCheck(updateFinish);
        let boolValue = !$("#" + id).val();


        //aki code
        // const boolValue = !$("#"+id).val();
        for (let aki = 0; aki < FINISH_COMB[position].length; aki++) {
            cutCheck[FINISH_COMB[position][aki]] = boolValue;
            polishCheck[FINISH_COMB[position][aki]] = boolValue;
            symmetryCheck[FINISH_COMB[position][aki]] = boolValue;
        }

        // if(finish.length == 0){
        //     setCutCheck(cutCheck.fill(false));
        //     setPolishCheck(polishCheck);
        //     setSymmetryCheck(symmetryCheck);

        // }else{
        //     setCutCheck(cutCheck);
        //     setPolishCheck(polishCheck);
        //     setSymmetryCheck(symmetryCheck);
        // }

        //aki code
        if (!finish.includes(id)) {
            //boolValue = true;
            setFinish([...finish, id]);


            setCutCheck(cutCheck);

            setPolishCheck(polishCheck);
            setSymmetryCheck(symmetryCheck);

        } else {
            //  boolValue = false;
            finish.splice(finish.indexOf(id), 1);
            setCutCheck([]);
            setPolishCheck([]);
            setSymmetryCheck([]);
        }
        let cutFinal = [];
        let polishFinal = [];
        let symmetryFinal = [];
        $(".ceva_cut").each(function (key) {
            if (!cut.includes(parseInt(this.id)) && cutCheck[key] == true) {
                cutFinal.push(parseInt(this.id))
            }
        });
        setCut(cutFinal)
        $(".ceva_polish").each(function (key) {
            if (!polish.includes(parseInt(this.id)) && polishCheck[key] == true) {
                polishFinal.push(parseInt(this.id))
            }
        });
        setPolish(polishFinal)
        $(".ceva_symmetry").each(function (key) {
            if (!symmetry.includes(parseInt(this.id)) && symmetryCheck[key] == true) {
                symmetryFinal.push(parseInt(this.id))
            }
        });
        setSymmetry(symmetryFinal)

        //.prop('checked', true).trigger("change");



    }


    const handleFilterCut = (position, id) => {

        const updatedCut = cutCheck && cutCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setCutCheck(updatedCut);

        if (!cut.includes(id)) {
            setCut([...cut, id]);
        } else {
            cut.splice(cut.indexOf(id), 1);
        }
    }

    const handleFilterPolish = (position, id) => {

        const updatePolish = polishCheck && polishCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setPolishCheck(updatePolish);

        if (!polish.includes(id)) {
            setPolish([...polish, id]);
        } else {
            polish.splice(polish.indexOf(id), 1);
        }
    }


    const handleFilterSymmetry = (position, id) => {

        const updateSymmetry = symmetryCheck && symmetryCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSymmetryCheck(updateSymmetry);

        if (!symmetry.includes(id)) {
            setSymmetry([...symmetry, id]);
        } else {
            symmetry.splice(symmetry.indexOf(id), 1);
        }
    }

    const handleFilterFluoresence = (position, id) => {

        const updateFluoresence = fluoresenceCheck && fluoresenceCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setFluoresenceCheck(updateFluoresence);

        if (!fluoresence.includes(id)) {
            setFluoresence([...fluoresence, id]);
        } else {
            fluoresence.splice(fluoresence.indexOf(id), 1);
        }
    }

    const handleFilterCertitficate = (position, id) => {

        const updateCertificate = certificateCheck && certificateCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setCertificateCheck(updateCertificate);

        if (!certificate.includes(id)) {
            setCertificate([...certificate, id]);
        } else {
            certificate.splice(certificate.indexOf(id), 1);
        }
    }

    const handleCarat = (event) => {

        setCarat({ ...carat, [event.target.name]: event.target.value });
    }

    const handlePrice = (event) => {

        setPrice({ ...price, [event.target.name]: event.target.value });
    }

    const handleFilterIntensity = (position, id) => {

        const updatedIntensity = intensityCheck && intensityCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setIntensityCheck(updatedIntensity);

        if (!fancy_color_intensity.includes(id)) {
            setFancyColorIntensity([...fancy_color_intensity, id]);
        } else {
            fancy_color_intensity.splice(fancy_color_intensity.indexOf(id), 1);
        }
    }

    const handleFilterOvertone = (position, id) => {

        const updateOvertone = overtoneCheck && overtoneCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setOvertoneCheck(updateOvertone);

        if (!fancy_color_overtone.includes(id)) {
            setFancyColorOvertone([...fancy_color_overtone, id]);
        } else {
            fancy_color_overtone.splice(fancy_color_overtone.indexOf(id), 1);
        }
    }


    const handleFilterFancyColor = (position, id) => {

        const updateFancycolor = fancycolorCheck && fancycolorCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setFancyColorCheck(updateFancycolor);

        if (!fancy_color.includes(id)) {
            setFancyColor([...fancy_color, id]);
        } else {
            fancy_color.splice(fancy_color.indexOf(id), 1);
        }
    }

    const handleSelectColor = async (e) => {
        
        setSelectColor(e.target.checked);

        filter.is_fancy = e.target.checked
        handleEditPage(0)
        mixpanel.track('Diamond_filter Jan25');
        dispatch(getAllProductsFilter(pathname, filter));

        //selectColor
        // handleFilter(e)
        if (e.target.checked) {
            $('#d_intensity').css({
                display: 'block'
            })
            $('#d_overtone').css({
                display: 'block'
            })
            $('#d_fancycolor').css({
                display: 'block'
            })
            $('#d_color').css({
                display: 'none'
            })
            $('.handlehidesshow').css({
                display: 'none'
            })
        } else {
            $('#d_intensity').css({
                display: 'none'
            })
            $('#d_overtone').css({
                display: 'none'
            })
            $('#d_fancycolor').css({
                display: 'none'
            })
            $('#d_color').css({
                display: 'block'
            })
            $('.handlehidesshow').css({
                display: 'block'
            })

        }

    }

    const handleFilterWhiteColor = (id) => {

        if (!color.includes(id)) {
            setColor([...color, id]);
        } else {
            color.splice(color.indexOf(id), 1);
        }



        if ($('.shape-items-show li.colorc_' + id).hasClass('active')) {
            $('.shape-items-show li.colorc_' + id).removeClass('active')
        } else {
            $('.shape-items-show li.colorc_' + id).addClass('active')
        }
    }
    const handleFilterMilky = (position, id) => {

        const updatemilky = milkyCheck && milkyCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setMilkyCheck(updatemilky);

        if (!milky.includes(id)) {
            setMilky([...milky, id]);
        } else {
            milky.splice(milky.indexOf(id), 1);
        }
    }

    useEffect(() => {
        const allAttributes = attributes?.availability_status?.map(item => { return (item?.id) })
        const filteredwithoutall = allAttributes?.filter(item => item !== 2321)
        setAllAttributesArray(filteredwithoutall)
    }, [attributes])


    useEffect(() => {
        if (checkedBoxId?.length > 0) {
            if (checkedBoxId.includes(2321) || arraysHaveSameValues(checkedBoxId, allAttributesArray)) {
                setCheckedBoxId([-1]);
            }
        }
    }, [checkedBoxId])

    const handleFilterAvailabilty = (value, id) => {
        if (value == false && id == 2321) {
            setCheckedBoxId([]);
        } else {
            if (value == true) {
                setCheckedBoxId((prevItems) => [...prevItems, id]);
            } else {
                if (value == false && checkedBoxId?.includes(-1)) {
                    const filteredwithone = allAttributesArray?.filter(item => item !== id)
                    setCheckedBoxId(filteredwithone)
                } else {
                    const filterdata = checkedBoxId?.filter(item => item !== id)
                    setCheckedBoxId(filterdata);
                }

            }
        }
    };

    const handleSelectBgm = async (e) => {
        setSelectBgm(e.target.checked)
        filter.no_BGM = e.target.checked
    }

    const handleResetAll = () => {

        setShape([]);
        setClarityCheck(new Array(attributes && attributes.clarity && attributes.clarity.length).fill(false));
        setClarity([]);
        setCutCheck(new Array(attributes && attributes.cut && attributes.cut.length).fill(false));
        setCut([]);
        setPolishCheck(new Array(attributes && attributes.polish && attributes.polish.length).fill(false))
        setPolish([]);
        setSymmetryCheck(new Array(attributes && attributes.symmetry && attributes.symmetry.length).fill(false));
        setSymmetry([]);
        setFluoresenceCheck(new Array(attributes && attributes.fluorescence && attributes.fluorescence.length).fill(false));
        setFluoresence([]);
        setMilkyCheck(new Array(attributes && attributes.milky && attributes.milky.length).fill(false));
        setMilky([]);
        setCertificateCheck(new Array(attributes && attributes.certificate && attributes.certificate.length).fill(false));
        setCertificate([]);
        setCarat({
            from: "",
            to: ""
        })
        setPrice({
            from: "",
            to: ""
        })
        setColor([]);
        setStatusCheck(new Array(attributes && attributes.availability_status && attributes.availability_status.length).fill(false));
        setAvailabilityStatus([]);
        setSelectBgm(false);

        setCaratCheckboxCheck(new Array(CaratDiamondJson && CaratDiamondJson.length).fill(false))
        setIntensityCheck(new Array(attributes && attributes.fancy_intensity && attributes.fancy_intensity.length).fill(false));
        setFancyColorIntensity([]);
        setOvertoneCheck(new Array(attributes && attributes.fancy_overtone && attributes.fancy_overtone.length).fill(false));
        setFancyColorOvertone([]);
        setFancyColorCheck(new Array(attributes && attributes.fancy_color && attributes.fancy_color.length).fill(false));
        setFancyColor([]);
        setSelectColor(false);
        setFinishCheck(new Array(attributes && attributes.finish && attributes.finish.length).fill(false));
        setFinish([]);
        setSupplierCheck(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false))
        setSupplier([])
        if ($('.shape-items-show li').hasClass('active')) {
            $('.shape-items-show li').removeClass('active')
        }

        handleEditPage(0)
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname, {}));
        setDefaultSeller([])
    }


    const handleFilterCaratCheckbox = (From, To, position) => {


        const updateCaratCheckbox = caratcheckboxCheck && caratcheckboxCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return false;
            }
        });

        setCaratCheckboxCheck(updateCaratCheckbox);
        if (updateCaratCheckbox[position]) {
            setCarat({
                from: From,
                to: To
            })
        } else {
            setCarat({
                from: '',
                to: ''
            })
        }
    }


    const handleSupplier = (position, id) => {

        const updatedSupplier = supplierCheck && supplierCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSupplierCheck(updatedSupplier);

        if (!supplier.includes(id)) {
            setSupplier([...supplier, id]);
        } else {
            supplier.splice(supplier.indexOf(id), 1);
        }
    }

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(makeRequestPost('top-sellers', { category_slug: 'diamonds', search: inputValue }));
            }, 500);
        });

    const chooseOption = (e) => {
        sessionStorage.setItem('searchIdFilter', JSON.stringify(e))
        setDefaultSeller(e)
        var selected = [];
        e.forEach((el) => {
            selected.push(el.value)
        })
        setSellerList(selected)
    }

    return (
        <div className="col-lg-3 ">
            <div className="dashboard-sidebar ">
                <div className="filter_list">
                    <form onSubmit={handleFilter}>
                        <div className="filter_side">

                            <div className="filter_top  d_shape">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb page_head">
                                        <FormattedMessage id="category.diamonds" defaultMessage="Diamonds" />
                                    </h4>
                                    <p className="font-16 black-3B fm ">
                                        <FormattedMessage id="addsingleproduct.shape" defaultMessage="Shape" />
                                    </p>
                                </div>
                                <ul id="myList" className="shape-items-show">
                                    {
                                        attributes && attributes?.shape && attributes?.shape && attributes.shape.map((shape, key) => {
                                            return (
                                                <li
                                                    className={key < 8 ? 'c_show shapec_' + shape?.id : 'c_hide shapec_' + shape?.id}
                                                    onClick={(e) => handleFilterShape(e, shape?.id)}>
                                                    <img src={ShapeImageUrl(shape?.image)} alt="" />
                                                    <span>{shape?.name}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <p id="loadMore" onClick={(e) => loadMoreShape('d_shape')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>

                            <div className="filter_top">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat" /></h4>
                                </div>

                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" value={carat.from} placeholder={useIntl().formatMessage({ id: 'Low' })} name="from" onChange={handleCarat} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" value={carat.to} className="form-control" name="to" onChange={handleCarat} placeholder={useIntl().formatMessage({ id: 'High' })} />
                                    </div>
                                </div>

                                <div className="filter_top mt-3 d_carat_checkbox">
                                    <div className="multi_price">
                                        {
                                            CaratDiamondJson && CaratDiamondJson.map((data, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={data.display_name} checked={caratcheckboxCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterCaratCheckbox(data.from, data.to, key)} />
                                                        <label htmlFor={data.id}>{data.display_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('d_carat_checkbox')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                                </div>

                            </div>

                            <div className="filter_top mt-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.color" defaultMessage="Color" /></h4>
                                </div>
                                <div className="cus_switch d-flex align-item-center">
                                    <p className="font-14 fm black-3B pr-3"><FormattedMessage id="addsingleproduct.White" defaultMessage="White" /></p>
                                    <label className="switch">
                                        <input type="checkbox" checked={selectColor} onChange={handleSelectColor} />
                                        <span className="slider round"></span>
                                    </label>
                                    <p className="gray-75 font-14 fm pl-3"><FormattedMessage id="addsingleproduct.Fancy" defaultMessage="Fancy" /></p>
                                </div>
                            </div>

                            <div className="filter_top d_color mt-3" id="d_color" style={{ display: 'block' }}>
                                <ul id="myList" className="shape-items-show">
                                    {
                                        attributes && attributes?.color && attributes?.color && attributes.color.map((color, key) => {
                                            return (
                                                <li className={key < 12 ? 'c_show colorc_' + color.id : 'c_hide colorc_' + color.id} onClick={() => handleFilterWhiteColor(color.id)}><span>{color.name}</span></li>
                                            )
                                        })
                                    }
                                </ul>
                                <p id="loadMore" onClick={(e) => loadMoreShape('d_color')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>

                            <div className="filter_top mt-3 d_intensity" id="d_intensity" style={{ display: 'none' }}>
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.finishcolorintensity" defaultMessage="Fancy Intensity" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.fancy_intensity && attributes.fancy_intensity.map((data, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={intensityCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterIntensity(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_intensity')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>

                            <div className="filter_top mt-3 d_overtone" id="d_overtone" style={{ display: 'none' }}>
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.finishcolorovertone" defaultMessage="Fancy Overtone" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.fancy_overtone && attributes.fancy_overtone.map((data, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={overtoneCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterOvertone(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_overtone')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>

                            <div className="filter_top mt-3 d_fancycolor" id="d_fancycolor" style={{ display: 'none' }}>
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.finishcolor" defaultMessage="Fancy Color" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.fancy_color && attributes.fancy_color.map((data, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={fancycolorCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterFancyColor(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_fancycolor')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>

                            <div className="filter_top mt-3  d_clarity">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.clarity" defaultMessage="Clarity" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.clarity && attributes.clarity.map((data, key) => {
                                            return (
                                                <div className={key < 8 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={clarityCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterClarity(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_clarity')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>

                            <div className="filter_top mt-3  d_finish">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.finish" defaultMessage="Finish" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.finish && attributes.finish.map((data, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input
                                                        type="checkbox"
                                                        value={finishCheck[key]}
                                                        checked={finishCheck[key]}
                                                        id={data.id}
                                                        className="checkbox"
                                                        onChange={() => handleFilterFinish(key, data.id, data.a_name)}
                                                    />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            {/* //CutAks */}
                            {(shape.includes(35) || shape.length == 0) &&

                                <div className="filter_top mt-3 d_cut">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.cut" defaultMessage="Cut" /></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes?.cut && attributes.cut.map((data, key) => {
                                                return (
                                                    <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={data.name} checked={cutCheck[key]} id={data.id} className="checkbox ceva_cut" onChange={() => handleFilterCut(key, data.id)} />
                                                        <label htmlFor={data.id}>{data.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }

                            <div className="filter_top mt-3 d_polish">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.polish" defaultMessage="Polish" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.polish && attributes.polish.map((data, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={polishCheck[key]} id={data.id} className="checkbox ceva_polish" onChange={() => handleFilterPolish(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="filter_top mt-3 d_sym">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.symmetry" defaultMessage="Symmetry" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.symmetry && attributes.symmetry.map((data, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={symmetryCheck[key]} id={data.id} className="checkbox ceva_symmetry" onChange={() => handleFilterSymmetry(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>

                            <div className="filter_top mt-3 d_flu">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.fluorescence" defaultMessage="Fluorescence" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.fluorescence && attributes.fluorescence.map((data, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={fluoresenceCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterFluoresence(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>


                            <div className="filter_top mt-3 handlehidesshow">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.milky" defaultMessage="Milky" /></h4>
                                </div>
                            </div>
                            <div className="multi_price mt-3" style={{ display: 'block' }}>
                                {
                                    attributes && attributes?.milky && attributes.milky.map((data, key) => {
                                        return (
                                            <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                <input type="checkbox" value={data.name} checked={milkyCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterMilky(key, data.id)} />
                                                <label htmlFor={data.id}>{data.name}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="filter_top mt-3 handlehidesshow">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.bgm" defaultMessage="No BGM" /></h4>
                                </div>
                                <div className="cus_switch d-flex align-item-center">
                                    {/* <p className="font-14 fm black-3B pr-3"><FormattedMessage id="addsingleproduct.No" defaultMessage="No" /></p> */}
                                    <label className="switch">
                                        <input type="checkbox" onChange={handleSelectBgm} checked={selectBgm} />
                                        <span className="slider round"></span>
                                    </label>
                                    {/* <p className="gray-75 font-14 fm pl-3"><FormattedMessage id="addsingleproduct.Yes" defaultMessage="Yes" /></p> */}
                                </div>
                            </div>

                            <div className="filter_top mt-3 d_cert">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.certificate" defaultMessage="Certificate" /></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.certificate && attributes.certificate.map((data, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={certificateCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterCertitficate(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_cert')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                            </div>

                            <div className="filter_top mt-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="Status" defaultMessage="Status" /></h4>
                                </div>
                            </div>

                            <div className="filter_top d_color mt-3" id="d_color" style={{ display: 'block' }}>
                                <div className="form-group">
                                    {
                                        attributes && attributes?.availability_status && attributes.availability_status.map((data, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" id={`attributes${data.id}`} className="checkbox attributescheckbox" checked={checkedBoxId?.includes(-1) ? true : !checkedBoxId?.includes(-1) && checkedBoxId?.includes(data.id) ? true : false} onChange={(e) => handleFilterAvailabilty(e?.target?.checked, data.id)} />
                                                    <label htmlFor={`attributes${data.id}`} >{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="filter_top mb-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.price-ct" defaultMessage="Price/ct" /></h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" placeholder={useIntl().formatMessage({ id: 'Low' })} value={price.from} name="from" onChange={handlePrice} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={price.to} name="to" onChange={handlePrice} placeholder={useIntl().formatMessage({ id: 'High' })} />
                                    </div>
                                </div>
                            </div>

                            <div className="filter_top mt-3  d_seller">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="TopSupplier" defaultMessage="TopSupplier" /></h4>
                                </div>
                                <div className="multi_price mt-3 autocomplete" style={{ 'position': 'relative', paddingBottom: '50px' }}>
                                    <AsyncSelect
                                        value={defaultSeller}
                                        isMulti
                                        onChange={chooseOption}
                                        loadOptions={promiseOptions}
                                        placeholder={'Search'}
                                        noOptionsMessage={({ inputValue }) => "No result"} />
                                </div>
                                {
                                    attributes && attributes?.top_sellers && attributes.top_sellers.length > 20 &&
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('d_seller')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" /></p>
                                }
                            </div>

                        </div>
                        <div className="filter_top">
                            <div className="filter_btn">
                                <button className="btn btn-black" type="submit"><FormattedMessage id="ApplyFilters" defaultMessage="Apply filters" /></button>
                                <p
                                    className="font-14 gray-65 text-center mt-3"
                                    type="button"
                                    onClick={handleResetAll}>
                                    <FormattedMessage id="Reset_all" defaultMessage="Reset all" />
                                    <span className="icon-close_1"></span>
                                </p>
                            </div>
                        </div>
                    </form>

                </div>

            </div>
        </div>
    )
}

export default Sidebar
