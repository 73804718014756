import { getHeader, hashReqDecodeData, hashResEncodeData, getFormDataHeader } from "../helper";
import api from "../api";
import { SC, url } from "../config";

//getFormDataHeader
export const makeFormDataRequestPost = async (URL, DATA) => {

    // const encrypted_data = DATA ? (SC === 0 ? DATA : hashResEncodeData(DATA)) : "";

    const { data } = await api.post(URL, DATA, getFormDataHeader()).catch((error) => {

        if (error.response && error.response.status === 401) {
            localStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('user');
            window.location.href = url;
        }
    });

    if (SC == 1 && data?.data?.length > 0) {
        const decrypted_data = hashReqDecodeData(data?.data)
        data.data = decrypted_data;
        // console.log("decrypted_data--", decrypted_data);
    }

    if (data && data?.user_status === false) {
        // if (data && data.user_status !== undefined && !data.user_status) {
        localStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('user');
        window.location.href = url;
    } else {
        return data
    }
}

export const makeRequestPost = async (URL, DATA) => {
    // console.log("DATA::", DATA);
    const encrypted_data = DATA ? (SC === 0 ? DATA : hashResEncodeData(DATA)) : "";
    const { data } = await api.post(URL, encrypted_data, getHeader()).catch(function (error) {
        if (error?.response && error?.response?.status === 401) {
            localStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('user');
            window.location.href = url;
        }
    });

    if (SC == 1 && data?.data?.length > 0) {
        const decrypted_data = hashReqDecodeData(data?.data)
        data.data = decrypted_data;
    }    

    if (data && data?.user_status === false) {
        // if (data && data.user_status !== undefined && !data.user_status) {
        localStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('user');
        window.location.href = url;
    } else {
        return data
    }
}

export const makeRequestGet = async (URL) => {
    // console.log('data sending in get api--', URL);
    const { data } = await api.get(URL, getHeader()).catch(function (error) {
        // console.log(error);
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('user');
            window.location.href = url;
        }
    });

    if (SC == 1 && data?.data?.length > 0) {
        const decrypted_data = await hashReqDecodeData(data?.data)
        data.data = decrypted_data;
    }


    if (data && data?.user_status === false) {
        // if (data && data?.user_status !== undefined && !data?.user_status) {
        localStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('user');
        window.location.href = url;
    } else {
        return data
    }
}