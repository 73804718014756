import React, { Fragment, useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { getPolicy } from '../../actions/HomePageActions';
import parse from "html-react-parser";
import Loader from '../layout/Loader';
import MetaData from '../layout/MetaData';

const Privacy = () => {

    const dispatch = useDispatch();
    const { Policy, loading } = useSelector(state => state.policy);

    useEffect(() => {
        dispatch(getPolicy())
    }, [dispatch])

    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <MetaData title={`Privacy Policy`} />
                <Header />
                <section className="testimonal container py-5">
                    <h2 className="mt-5 mb-3 text-center black fm font-40 ">{Policy && Policy?.page && Policy?.page?.title}</h2>
                    {Policy && Policy?.page && parse(Policy.page.content)}
                </section>
                <Footer />
            </Fragment>
        </Fragment>
    )
}

export default Privacy